import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD_DATA',
    'LOAD_DATA_SUCCESS',
    'LOAD_DATA_FAIL',

    'LOAD_MORE',

    'LOAD_DETAIL',
    'LOAD_DETAIL_SUCCESS',
    'LOAD_DETAIL_FAIL',

    'INVITE',
    'INVITE_SUCCESS',
    'INVITE_FAIL',

    'REMOVE',
    'REMOVE_SUCCESS',
    'REMOVE_FAIL',

    'RESET_PASSWORD',

    'SET_MEMBER_ROLE',
    'SET_MEMBER_ROLE_SUCCESS',
    'SET_MEMBER_ROLE_FAIL',

    'SET_MEMBER_IS_ACTIVE',
    'SET_MEMBER_IS_ACTIVE_SUCCESS',
    'SET_MEMBER_IS_ACTIVE_FAIL',
  ],
  {
    prefix: 'COMPANY_MEMBERS.',
  },
);
