import mirrorCreator from 'mirror-creator';
import contactsActionTypes from 'shared/constants/CONTACTS_ACTIONS';

const NAME = 'COMPANY';

export const actionTypes = mirrorCreator(
  [
    'LOAD',
    'LOAD_SUCCESS',
    'LOAD_FAIL',

    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_FAIL',

    'UPDATE',
    'UPDATE_SUCCESS',
    'UPDATE_FAIL',

    ...contactsActionTypes,
  ],
  {
    prefix: `${NAME}.`,
  },
);

export { NAME };
