import { actionTypes } from './actionTypes';

function updateWidgets(widgets, data) {
  return widgets.map((widget) => {
    if (widget.widget_id === data.id) {
      return {
        ...widget,
        ...data,
      };
    }

    return widget;
  });
}

export const widgetReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.EDIT_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: state.ids.reduce((acc, id) => {
          const widget = state.data[id];

          if (widget.children) {
            const children = widget.children.map((child) => ({
              ...child,
              widgets: updateWidgets(child.widgets, data),
            }));

            acc[id] = {
              ...widget,
              ...(widget.widgets && { widgets: updateWidgets(widget.widgets, data) }),
              children,
            };

            return acc;
          }

          acc[id] = {
            ...widget,
            widgets: updateWidgets(widget.widgets, data),
          };

          return acc;
        }, {}),
      };
    }

    default:
      return undefined;
  }
};
