import { actionTypes } from './actionTypes';
import { companySources } from '../sources';

const loadCompanyAtolConfig = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: companySources.atolConfig,
      params,
    },
  },
});

const loadMoreCompanyAtolConfig = () => ({
  type: actionTypes.LOAD_MORE,
});

const createAtolConfig = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.atolConfig,
      data,
    },
  },
});

const editAtolConfig = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: companySources.atolConfigDetail(id),
      data,
    },
  },
});

const deleteAtolConfig = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: companySources.atolConfigDetail(id),
      id,
    },
  },
});

export {
  loadCompanyAtolConfig,
  loadMoreCompanyAtolConfig,
  createAtolConfig,
  editAtolConfig,
  deleteAtolConfig,
};
