import { actionTypes } from './actionTypes';

const INITIAL_STATE = {
  isOpen: false,
  modalType: null,
  routerModalType: null,
  prevModal: null,
  data: {},
  state: null,
};

export const modalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.OPEN:
      return {
        ...state,
        isOpen: true,
        modalType: action.payload.modalType,
        routerModalType: action.payload.modalType,
        data: action.payload.data,
        state: action.payload.state,
      };

    case actionTypes.CLOSE:
      return {
        ...state,
        isOpen: false,
        modalType: null,
        data: {},
        state: null,
      };

    case actionTypes.OPEN_ROUTER_MODAL:
      return {
        ...state,
        isOpen: true,
        routerModalType: action.payload.modalType,
        data: action.payload.data,
        state: action.payload.modalState,
      };

    case actionTypes.CLOSE_ROUTER_MODAL:
      return {
        ...state,
        isOpen: false,
        routerModalType: null,
        data: {},
        state: null,
      };

    case actionTypes.ADD_PREVIOUS_MODAL:
      return {
        ...state,
        prevModal: {
          modalType: action.payload.modalType,
          data: action.payload.data,
        },
      };

    case actionTypes.REMOVE_PREVIOUS_MODAL:
      return {
        ...state,
        prevModal: null,
      };

    case actionTypes.CHANGE_STATE:
      return {
        ...state,
        state: action.payload.state,
      };

    case actionTypes.EDIT_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload.data },
      };

    default:
      return state;
  }
};
