import map from 'lodash/map';
import { actionTypes } from './actionTypes';

export const companyAtolConfigReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.EDIT_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: map(state.data, (item) => {
          if (item.id === data.id) {
            return data;
          }

          return item;
        }),
      };
    }

    case actionTypes.DELETE_SUCCESS: {
      const { id } = action.meta.previousAction.payload.request;

      return {
        ...state,
        data: state.data.filter((item) => item.id !== id),
        meta: { ...state.meta, count: state.meta.count - 1 },
      };
    }

    default:
      return undefined;
  }
};
