import { actionTypes } from './actionTypes';
import { companySources } from '../sources';

const loadCompanyLegalsInfo = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: companySources.legalInfo,
      params,
    },
  },
});

const loadMoreCompanyLegalsInfo = () => ({
  type: actionTypes.LOAD_MORE,
});

const createLegalInfo = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.legalInfo,
      data,
    },
  },
});

const editLegalInfo = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: companySources.legalInfoDetail(id),
      data,
    },
  },
});

const deleteLegalInfo = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: companySources.legalInfoDetail(id),
      id,
    },
  },
});

export { loadCompanyLegalsInfo, loadMoreCompanyLegalsInfo, createLegalInfo, editLegalInfo, deleteLegalInfo };
