import { actionTypes } from 'entities/order';

const INITIAL_STATE = {
  checks: null,
};

export const bookingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.CREATE:
      return {
        ...state,
        checks: null,
      };

    case actionTypes.CREATE_FAIL:
      return {
        ...state,
        checks: action.error.response.data.checks,
      };

    default:
      return state;
  }
};
