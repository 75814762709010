import { actionTypes } from './actionTypes';
import { companySources } from '../sources';

const loadCompanyEmailsConfig = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: companySources.emailConfig,
      params,
    },
  },
});

const loadMoreCompanyEmailsConfig = () => ({
  type: actionTypes.LOAD_MORE,
});

const createEmailConfig = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.emailConfig,
      data,
    },
  },
});

const editEmailConfig = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: companySources.emailConfigDetail(id),
      data,
    },
  },
});

const deleteEmailConfig = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: companySources.emailConfigDetail(id),
      id,
    },
  },
});

export {
  loadCompanyEmailsConfig,
  loadMoreCompanyEmailsConfig,
  createEmailConfig,
  editEmailConfig,
  deleteEmailConfig,
};
