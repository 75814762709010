import map from 'lodash/map';
import { actionTypes } from './actionTypes';

export const companyMembersReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DETAIL_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: map(state.data, (item) => {
          if (item.id === data.id) {
            return {
              ...item,
              roles: data.roles,
            };
          }

          return item;
        }),
      };
    }

    case actionTypes.SET_MEMBER_IS_ACTIVE_SUCCESS: {
      const {
        data: { is_active: isActive },
        id,
      } = action.meta.previousAction.payload.request;

      return {
        ...state,
        data: map(state.data, (item) => {
          if (item.id === id) {
            return { ...item, is_active: isActive };
          }

          return item;
        }),
      };
    }

    case actionTypes.REMOVE_SUCCESS: {
      const { id } = action.meta.previousAction.payload.request;

      return {
        ...state,
        data: state.data.filter((item) => item.id !== id),
        meta: { ...state.meta, count: state.meta.count - 1 },
      };
    }

    default:
      return undefined;
  }
};
