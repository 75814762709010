import { actionTypes } from './actionTypes';
import { membersUsersSources } from './sources';

const loadMembersUsers = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: membersUsersSources.root,
      params,
    },
  },
});

const loadMoreMembersUsers = (params = {}) => ({
  type: actionTypes.LOAD_MORE,
  payload: {
    request: {
      url: membersUsersSources.root,
      params,
    },
  },
});

const loadMembersUser = (id: number | string) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    request: {
      url: membersUsersSources.detail(id),
    },
  },
});

const updateMembersUserPermissions = (data: any) => ({
  type: actionTypes.UPDATE,
  payload: {
    request: {
      method: 'POST',
      url: membersUsersSources.permissionsDetail(data.id),
      data: {
        permissions: data.permissions,
      },
    },
  },
});

const setMemberIsActive = (id: number | string, data: any) => ({
  type: actionTypes.SET_MEMBER_IS_ACTIVE,
  payload: {
    request: {
      method: 'POST',
      url: membersUsersSources.setMemberIsActive(id),
      data,
      id,
    },
  },
});

export {
  loadMembersUsers,
  loadMoreMembersUsers,
  loadMembersUser,
  updateMembersUserPermissions,
  setMemberIsActive,
};
