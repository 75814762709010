import { actionTypes } from './actionTypes';
import { companySources } from './sources';

const loadCompany = () => ({
  type: actionTypes.LOAD,
  payload: {
    request: {
      url: companySources.root,
    },
  },
});

const loadEmails = () => ({
  type: actionTypes.LOAD_EMAILS,
  payload: {
    request: {
      url: companySources.emails,
    },
  },
});

const loadPhones = () => ({
  type: actionTypes.LOAD_PHONES,
  payload: {
    request: {
      url: companySources.phones,
    },
  },
});

const createCompany = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.root,
      data,
    },
  },
});

const createPhone = (data: any) => ({
  type: actionTypes.CREATE_PHONE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.phones,
      data,
    },
  },
});

const createEmail = (data: any) => ({
  type: actionTypes.CREATE_EMAIL,
  payload: {
    request: {
      method: 'POST',
      url: companySources.emails,
      data,
    },
  },
});

const updateCompany = (data: any) => ({
  type: actionTypes.UPDATE,
  payload: {
    request: {
      method: 'PATCH',
      url: companySources.root,
      data,
    },
  },
});

const deletePhone = (data: any) => ({
  type: actionTypes.DELETE_PHONE,
  payload: {
    request: {
      method: 'DELETE',
      url: companySources.phonesDetail(data.id),
    },
    id: data.id,
  },
});

const deleteEmail = (data: any) => ({
  type: actionTypes.DELETE_EMAIL,
  payload: {
    request: {
      method: 'DELETE',
      url: companySources.emailsDetail(data.id),
    },
    id: data.id,
  },
});

const setDefaultPhone = (id: number | string) => ({
  type: actionTypes.SET_DEFAULT_PHONE,
  payload: {
    request: {
      url: companySources.defaultPhone(id),
    },
    id,
  },
});

const setDefaultEmail = (id: number | string) => ({
  type: actionTypes.SET_DEFAULT_EMAIL,
  payload: {
    request: {
      url: companySources.defaultEmail(id),
    },
    id,
  },
});

export {
  loadCompany,
  createCompany,
  updateCompany,
  loadEmails,
  loadPhones,
  createPhone,
  createEmail,
  deletePhone,
  deleteEmail,
  setDefaultPhone,
  setDefaultEmail,
};
