import { actionTypes } from './actionTypes';

const toggleSidebarState = () => ({
  type: actionTypes.TOGGLE_SIDEBAR_STATE,
});

const setSidebarState = (data: any) => ({
  type: actionTypes.SET_SIDEBAR_STATE,
  payload: {
    data,
  },
});

const setUserAgent = (data: any) => ({
  type: actionTypes.SET_USER_AGENT,
  payload: {
    data,
  },
});

export { toggleSidebarState, setSidebarState, setUserAgent };
