import { actionTypes } from './actionTypes';
import { updateOrder } from 'shared/helpers/order';

export const purchasesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SALE_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: state.data.map((item) => (item.id === data.id ? data : item)),
      };
    }

    case actionTypes.LOAD_ORDER_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: state.data.map((item) => (item.id === data.id ? data : item)),
      };
    }

    case actionTypes.UPDATE_STATUS: {
      const { id, newStatus } = action.payload;

      return {
        ...state,
        data: updateOrder(state.data, { id, field: 'status', value: newStatus }),
      };
    }

    case actionTypes.UPDATE_MANAGER: {
      const { id, manager } = action.payload;

      return {
        ...state,
        data: updateOrder(state.data, { id, field: 'manager', value: manager }),
      };
    }

    case actionTypes.ADD_NEW: {
      const isExist = state.data.find((i) => i.id === action.payload.data.id);

      if (isExist) return state;

      return {
        ...state,
        data: [action.payload.data, ...state.data],
      };
    }

    default:
      return undefined;
  }
};
