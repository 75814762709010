import styled, { css } from 'styled-components';
import { darken } from 'polished';
import COLORS from 'shared/constants/COLORS';

interface StyledLinkProps {
  underline?: boolean;
  black?: boolean;
}

export const StyledLinkCSS = css<StyledLinkProps>`
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  color: ${(props) => (props.black ? 'black' : COLORS.BLUE)};
  transition: all 0.2s ease-in-out;

  &:hover {
    color: ${(props) => (props.black ? 'black' : darken(0.15, COLORS.BLUE))};
    text-decoration: ${(props) => (props.black ? 'underline' : null)};
  }

  /* WebKit gets its native focus styles. */
  /* &:focus {
    box-shadow: none;
    outline-width: 5px;
    outline-style: solid;
    outline-color: Highlight;

    @media (-webkit-min-device-pixel-ratio: 0) {
      & {
        outline-color: -webkit-focus-ring-color;
        outline-style: auto;
      }
    }
  } */
`;

export const StyledLink = styled.span`
  ${StyledLinkCSS}
`;

export const StyledLinkTag = styled.a`
  ${StyledLinkCSS}
`;
