import { LANGUAGES } from 'shared/constants/LANGUAGES';

const isAxiosRequest = (action) => action.payload && action.payload.request;

const i18nRequests = () => (next) => (action) => {
  if (!isAxiosRequest(action)) {
    return next(action);
  }

  const { payload } = action;

  if (payload.lng) {
    const url =
      payload.request.url.indexOf('?') !== -1
        ? payload.request.url.replace('?', `/translation/${payload.loadLng}?`)
        : `${payload.request.url}/translation/${payload.loadLng}`;
    const newAction = {
      ...action,
      payload: {
        ...payload,
        request: {
          ...payload.request,
          url: payload.loadLng === LANGUAGES.RU ? payload.request.url : url,
          headers: {
            'Accept-Language': payload.lng,
          },
        },
      },
    };

    return next(newAction);
  }

  return next(action);
};

export default i18nRequests;
