const INITIAL_STATE = {
  data: [],
  meta: {},
  state: {
    isLoading: true,
    isLoadingMore: false,
    isFail: false,
  },
};

interface Props {
  searchName: string;
  reducerFunction: (state: any, action: string) => any;
}

export function createSearchReducer(
  searchName: Props['searchName'] = '',
  reducerFunction?: Props['reducerFunction'],
) {
  return function searchReducer(state = INITIAL_STATE, action) {
    if (reducerFunction) {
      const newState = reducerFunction(state, action);

      if (newState) {
        return newState;
      }
    }

    switch (action.type) {
      case `SEARCH.LOAD_${searchName}`: {
        const { loadMore } = action.payload;

        return {
          ...state,
          data: loadMore ? state.data : [],
          meta: loadMore ? state.meta : {},
          counts: {},
          state: {
            isLoading: true,
            isFail: false,
          },
        };
      }
      case `SEARCH.LOAD_${searchName}_SUCCESS`: {
        const { loadMore } = action.meta.previousAction.payload;
        const { results, ...meta } = action.payload.data.data;

        return {
          ...state,
          data: loadMore ? [...state.data, ...results] : results,
          meta,
          state: {
            isLoading: false,
            isLoadingMore: false,
            isFail: false,
          },
        };
      }
      case `SEARCH.LOAD_${searchName}_FAIL`:
        return {
          ...state,
          data: [],
          meta: {},
          state: {
            isLoading: false,
            isLoadingMore: false,
            isFail: true,
          },
        };

      default:
        return state;
    }
  };
}
