module.exports = {
  TOKEN: '__t_',
  WS_TOKEN: '__ws_',
  CALL_CONTEXT: 'callContext',
  SIDEBAR_IS_OPEN: 'sidebarIsOpen',
  FILTERBARS: 'filterbars',
  QUERIES: 'queries',
  ALL_ROWS_EXPANDED: 'allRowsExpanded',
  REPORTS_DATES: 'reportsDates',
  COMPANY: 'company_id',
  REG_NOTICED: 'reg_noticed',
};
