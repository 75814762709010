import { actionTypes as announcementActionTypes } from 'entities/announcement';
import { updateSelectedTickets } from 'entities/announcement';

const bookingMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case announcementActionTypes.CLOSE_UPDATE_MODAL: {
      const state = store.getState();
      const { checks } = state.booking;

      if (checks) {
        store.dispatch(updateSelectedTickets(checks));
      }
      break;
    }
    default:
      break;
  }

  next(action);
};

export default bookingMiddleware;
