import { actionTypes } from './actionTypes';

const INITIAL_STATE = {
  isOpened: false,
  isLoading: false,
  closedByBookingModal: false,
  contextIsVisible: false,
};

export const poolSchemeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.OPEN:
      return {
        ...state,
        isOpened: true,
        closedByBookingModal: false,
      };

    case actionTypes.CLOSE:
      return {
        ...state,
        isOpened: false,
        closedByBookingModal: action.payload.closedByBookingModal,
      };
    case actionTypes.OPEN_CONTEXT:
      return {
        ...state,
        contextIsVisible: true,
      };
    case actionTypes.CLOSE_CONTEXT:
      return {
        ...state,
        contextIsVisible: false,
      };
    default:
      return state;
  }
};
