import { actionTypes } from './actionTypes';
import {accountSources} from './sources';

export const loadCurrentUser = () => ({
  type: actionTypes.LOAD,
  payload: {
    request: {
      url: accountSources.me,
    },
  },
});

export const setCurrentUser = (data: any) => ({
  type: actionTypes.SET_CURRENT_USER,
  payload: {
    data,
  },
});

export const editCurrentUser = (data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'POST',
      url: accountSources.me,
      data,
    },
  },
});

export const loadEmails = () => ({
  type: actionTypes.LOAD_EMAILS,
  payload: {
    request: {
      url: accountSources.emails,
    },
  },
});

export const loadPhones = () => ({
  type: actionTypes.LOAD_PHONES,
  payload: {
    request: {
      url: accountSources.phones,
    },
  },
});

export const createPhone = (data: any) => ({
  type: actionTypes.CREATE_PHONE,
  payload: {
    request: {
      method: 'POST',
      url: accountSources.phones,
      data,
    },
  },
});

export const createEmail = (data: any) => ({
  type: actionTypes.CREATE_EMAIL,
  payload: {
    request: {
      method: 'POST',
      url: accountSources.emails,
      data,
    },
  },
});

export const deletePhone = (data: any) => ({
  type: actionTypes.DELETE_PHONE,
  payload: {
    request: {
      method: 'DELETE',
      url: accountSources.phonesDetail(data.id),
    },
    id: data.id,
  },
});

export const deleteEmail = (data: any) => ({
  type: actionTypes.DELETE_EMAIL,
  payload: {
    request: {
      method: 'DELETE',
      url: accountSources.emailsDetail(data.id),
    },
    id: data.id,
  },
});

export const setDefaultPhone = (id: number | string) => ({
  type: actionTypes.SET_DEFAULT_PHONE,
  payload: {
    request: {
      url: accountSources.defaultPhone(id),
    },
    id,
  },
});

export const setDefaultEmail = (id: number | string) => ({
  type: actionTypes.SET_DEFAULT_EMAIL,
  payload: {
    request: {
      url: accountSources.defaultEmail(id),
    },
    id,
  },
});
