import { actionTypes } from 'entities/order';
import { updateOrder } from 'shared/helpers/order';

export const searchOrdersReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_STATUS: {
      const { id, newStatus } = action.payload;

      return {
        ...state,
        data: updateOrder(state.data, { id, field: 'status', value: newStatus }),
      };
    }

    default:
      return undefined;
  }
};
