import { actionTypes } from './actionTypes';
import { orderSources } from 'entities/order';

const closeChat = () => ({
  type: actionTypes.CLOSE,
});

const loadComments = (id: number | string) => ({
  type: actionTypes.LOAD_COMMENTS,
  payload: {
    request: {
      url: orderSources.comments(id),
    },
  },
});

const addComment = (id: number | string, data: any) => ({
  type: actionTypes.ADD_COMMENT,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.addComment(id),
      data,
    },
  },
});

const loadComment = (data: any) => ({
  type: actionTypes.LOAD_COMMENT,
  payload: {
    data,
  },
});

const loadNewStatus = (data: any) => ({
  type: actionTypes.LOAD_NEW_STATUS,
  payload: {
    data,
  },
});

const clearComments = () => ({
  type: actionTypes.CLEAR_COMMENTS,
});

const addCommentAttachment = (id: number | string, data: any, image: string) => ({
  type: actionTypes.ADD_COMMENT_ATTACHMENT,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.addCommentAttachment(id),
      data,
      headers: {
        'content-type': 'multipart/form-data',
      },
    },
    image,
  },
});

const addNewAttachments = (attachments) => ({
  type: actionTypes.ADD_NEW_ATTACHMENTS,
  payload: attachments,
});

export {
  closeChat,
  addComment,
  loadComments,
  loadComment,
  clearComments,
  loadNewStatus,
  addCommentAttachment,
  addNewAttachments,
};
