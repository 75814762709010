import Link from 'next/link';
import React from 'react';
import { StyledLink, StyledLinkTag } from './styles';

interface LinkProps {
  href: string;
  as?: string | null;
  simple?: boolean;
  underline?: boolean;
  disabled?: boolean;
  LinkComponent?: any;
  children: any;
  target?: string;
  rel?: string;
  data?: any;
  onClick?: any;
  black?: boolean;
}

const RouterLink = ({
  href,
  as = null,
  children,
  simple = false,
  underline = false,
  LinkComponent = StyledLinkTag,
  disabled = false,
  target,
  rel,
  ...rest
}: LinkProps) => {
  if (disabled) return children;

  return simple ? (
    <LinkComponent underline={underline} href={href}>
      {children}
    </LinkComponent>
  ) : (
    <Link href={href} as={as} prefetch={false} target={target} rel={rel} passHref>
      <StyledLink underline={underline} {...rest}>
        {children}
      </StyledLink>
    </Link>
  );
};

export default RouterLink;
