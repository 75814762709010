import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'SELECT_SEAT',
    'DELETE_SELECTED_TICKET',
    'CLEAR_SELECTED_TICKETS',
    'UPDATE_SELECTED_TICKETS',

    'LOAD_DETAIL',
    'LOAD_DETAIL_SUCCESS',
    'LOAD_DETAIL_FAIL',

    'LOAD_DETAIL_RAW',
    'LOAD_DETAIL_RAW_SUCCESS',
    'LOAD_DETAIL_RAW_FAIL',

    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_FAIL',

    'EDIT',
    'EDIT_SUCCESS',
    'EDIT_FAIL',

    'LOAD_DATA',
    'LOAD_DATA_SUCCESS',
    'LOAD_DATA_FAIL',

    'LOAD_MORE',

    'UPDATE',
    'UPDATE_SUCCESS',
    'UPDATE_FAIL',

    'DELETE',
    'DELETE_SUCCESS',
    'DELETE_FAIL',

    'UPDATE_TICKET_PRICE',

    'SET_SELECTED_ANNOUNCEMENT',

    'BULK_DELETE',
    'BULK_DELETE_SUCCESS',
    'BULK_DELETE_FAIL',

    'BULK_PRICE_UPDATE',
    'BULK_PRICE_UPDATE_SUCCESS',
    'BULK_PRICE_UPDATE_FAIL',

    'BULK_STATUS_UPDATE',
    'BULK_STATUS_UPDATE_SUCCESS',
    'BULK_STATUS_UPDATE_FAIL',

    'ADD_TICKETS',
    'ADD_TICKETS_SUCCESS',
    'ADD_TICKETS_FAIL',

    'DELETE_TICKETS',
    'DELETE_TICKETS_SUCCESS',
    'DELETE_TICKETS_FAIL',

    'REFUND_TICKETS',
    'REFUND_TICKETS_SUCCESS',
    'REFUND_TICKETS_FAIL',

    'SEPARATE_TICKETS',
    'SEPARATE_TICKETS_SUCCESS',
    'SEPARATE_TICKETS_FAIL',

    'CLOSE_UPDATE_MODAL',

    'LOAD_HISTORY',
    'LOAD_HISTORY_SUCCESS',
    'LOAD_HISTORY_FAIL',

    'LOAD_PRICE_CHANGE_SCHEDULE',
    'LOAD_PRICE_CHANGE_SCHEDULE_SUCCESS',
    'LOAD_PRICE_CHANGE_SCHEDULE_FAIL',

    'CREATE_PRICE_CHANGE_SCHEDULE',
    'CREATE_PRICE_CHANGE_SCHEDULE_SUCCESS',
    'CREATE_PRICE_CHANGE_SCHEDULE_FAIL',

    'EDIT_PRICE_CHANGE_SCHEDULE',
    'EDIT_PRICE_CHANGE_SCHEDULE_SUCCESS',
    'EDIT_PRICE_CHANGE_SCHEDULE_FAIL',

    'DELETE_PRICE_CHANGE_SCHEDULE',
    'DELETE_PRICE_CHANGE_SCHEDULE_SUCCESS',
    'DELETE_PRICE_CHANGE_SCHEDULE_FAIL',
  ],
  {
    prefix: 'ANNOUNCEMENT.',
  },
);
