import mirrorCreator from 'mirror-creator';
import contactsActionTypes from 'shared/constants/CONTACTS_ACTIONS';

const NAME = 'ACCOUNT';

export const actionTypes = mirrorCreator(
  [
    'LOAD',
    'LOAD_SUCCESS',
    'LOAD_FAIL',
    'EDIT',
    'EDIT_SUCCESS',
    'EDIT_FAIL',
    'SET_CURRENT_USER',
    ...contactsActionTypes,
  ],
  {
    prefix: `${NAME}.`,
  },
);

export { NAME };
