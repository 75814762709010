import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD_DATA',
    'LOAD_DATA_SUCCESS',
    'LOAD_DATA_FAIL',

    'LOAD_MORE',

    'CHANGE_PREORDER_EXTRA',
    'CHANGE_PREORDER_EXTRA_SUCCESS',
    'CHANGE_PREORDER_EXTRA_FAIL',

    'FINISH_PREORDER',
    'FINISH_PREORDER_SUCCESS',
    'FINISH_PREORDER_FAIL',

    'LOAD_DETAIL',
    'LOAD_DETAIL_SUCCESS',
    'LOAD_DETAIL_FAIL',
  ],
  {
    prefix: 'PREORDERS.',
  },
);
