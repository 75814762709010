import Router from 'next/router';
import { loadCurrentUser } from 'entities/account';
import { actionTypes as authActionTypes } from 'entities/auth';
import { addSessionToken } from 'entities/session';
import COOKIES from 'shared/constants/COOKIES';
import routes from 'shared/helpers/routes';
import { setCookie, removeCookie } from 'shared/lib/cookie';
import WebsocketService from 'shared/services/WebsocketService';

/*
TODO: fix error
error - unhandledRejection: Error: No router instance found.
You should only use "next/router" on the client side of your app.
 */

/* eslint-disable */
const authMiddleware = (store) => (next) => (action) => {
  if (action.error && action.error.response && action.error.response.status === 401) {
    removeCookie(COOKIES.TOKEN);
    removeCookie(COOKIES.WS_TOKEN);
    if (typeof window !== 'undefined') {
      window.location.href = routes.login;
    }
  }

  switch (action.type) {
    case authActionTypes.REGISTRATION_SUCCESS:
    case authActionTypes.LOGIN_SUCCESS: {
      const { nextUrl } = action.meta.previousAction.payload;
      const { access_token: token, ws_access_token: wsToken } = action.payload.data;

      setCookie(COOKIES.TOKEN, token);
      setCookie(COOKIES.WS_TOKEN, wsToken);
      WebsocketService.open(wsToken);
      next(addSessionToken(token));
      store.dispatch(loadCurrentUser()).then(() => {
        Router.replace(nextUrl || routes.root);
      });
      break;
    }
    default:
  }

  next(action);
};

export default authMiddleware;
