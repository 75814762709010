import { actionTypes } from './actionTypes';
import poolSources from 'shared/sources/pool';

const loadPool = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: poolSources.root,
      params,
    },
  },
});

const loadPoolMore = (params = {}) => ({
  type: actionTypes.LOAD_MORE,
  payload: {
    request: {
      url: poolSources.root,
      params,
    },
  },
});

const refreshPool = (params = {}) => ({
  type: actionTypes.REFRESH,
  payload: {
    request: {
      url: poolSources.root,
      params,
    },
  },
});

export { loadPool, loadPoolMore, refreshPool };
