import { NextPageContext } from 'next';

const redirect = (target, ctx: NextPageContext) => {
  if (ctx && ctx.res) {
    ctx.res.setHeader('location', target);
    ctx.res.statusCode = 303;
  }
};

export default redirect;
