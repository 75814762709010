import { omit } from 'lodash';
import { actionTypes } from './actionTypes';
import { parsersEventsSources } from './sources';

const loadParsersEvents = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: parsersEventsSources.root,
      params: {
        ...omit(params, ['date_id']),
        ...(params.external_id && { external_id: params.date_id }),
      },
    },
  },
});

const loadMoreParsersEvents = () => ({
  type: actionTypes.LOAD_MORE,
});

const linkParserWithEvent = (id: number | string, eventId: number | string) => ({
  type: actionTypes.LINK_PARSER_WITH_EVENT,
  payload: {
    request: {
      method: 'POST',
      url: parsersEventsSources.link(id),
      data: eventId,
      id,
      eventId,
    },
  },
});

const unlinkParserWithEvent = (id: number | string) => ({
  type: actionTypes.UNLINK_PARSER_WITH_EVENT,
  payload: {
    request: {
      method: 'GET',
      url: parsersEventsSources.unlink(id),
      id,
    },
  },
});

const setParserStatus = (id: number | string, status: number | string) => ({
  type: actionTypes.SET_PARSER_STATUS_SUCCESS,
  payload: {
    request: {
      method: 'POST',
      url: parsersEventsSources.status(id),
      data: status,
    },
    id,
  },
});

const addEvent = (data: any) => ({
  type: actionTypes.ADD_EVENT,
  payload: {
    request: {
      method: 'POST',
      url: parsersEventsSources.root,
      data,
    },
  },
});

const bulkIgnore = (data: any) => ({
  type: actionTypes.BULK_IGNORE,
  payload: {
    request: {
      method: 'POST',
      url: parsersEventsSources.bulkIgnore,
      data: {
        events: data.ids,
      },
    },
  },
});

export {
  loadParsersEvents,
  loadMoreParsersEvents,
  linkParserWithEvent,
  unlinkParserWithEvent,
  setParserStatus,
  addEvent,
  bulkIgnore,
};
