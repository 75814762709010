export default {
  root: 'announcement',
  detail: (id) => `announcement/${id}`,
  detailUpdate: (id) => `announcement/${id}/update`,
  detailRaw: (id) => `announcement/${id}/raw`,
  list: 'announcement/list',
  listv2: 'announcement/list/v2',
  updatePrice: (id) => `announcement/${id}/update-price`,
  bulkDelete: 'announcement/delete',
  bulkStatusUpdate: 'announcement/update-status',
  bulkPriceUpdate: 'announcement/update-price',
  addTickets: (id) => `announcement/${id}/add-tickets`,
  refundTickets: (id) => `announcement/${id}/refund-tickets`,
  deleteTickets: (id) => `announcement/${id}/delete-tickets`,
  separateTickets: (id) => `announcement/${id}/separate`,
  bulkCreate: 'announcement/bulk-create',
  history: (id) => `announcement/${id}/history`,
  priceChangeScheduleList: (id) => `announcement/${id}/price-change-schedule-list`,
  priceChangeScheduleDetail: (id, scheduleId) => `announcement/${id}/price-change-schedule/${scheduleId}`,
};
