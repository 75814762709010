import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD_DATA',
    'LOAD_DATA_SUCCESS',
    'LOAD_DATA_FAIL',

    'LOAD_MORE',

    'INVITE',
    'INVITE_SUCCESS',
    'INVITE_FAIL',

    'EDIT',
    'EDIT_SUCCESS',
    'EDIT_FAIL',

    'REMOVE',
    'REMOVE_SUCCESS',
    'REMOVE_FAIL',
  ],
  {
    prefix: 'COMPANY_PAYMENT_METHODS.',
  },
);
