export default [
  'LOAD_EMAILS',
  'LOAD_EMAILS_SUCCESS',
  'LOAD_EMAILS_FAIL',

  'LOAD_PHONES',
  'LOAD_PHONES_SUCCESS',
  'LOAD_PHONES_FAIL',

  'CREATE_PHONE',
  'CREATE_PHONE_SUCCESS',
  'CREATE_PHONE_FAIL',

  'CREATE_EMAIL',
  'CREATE_EMAIL_SUCCESS',
  'CREATE_EMAIL_FAIL',

  'UPDATE_PHONE',
  'UPDATE_PHONE_SUCCESS',
  'UPDATE_PHONE_FAIL',

  'UPDATE_EMAIL',
  'UPDATE_EMAIL_SUCCESS',
  'UPDATE_EMAIL_FAIL',

  'DELETE_PHONE',
  'DELETE_PHONE_SUCCESS',
  'DELETE_PHONE_FAIL',

  'DELETE_EMAIL',
  'DELETE_EMAIL_SUCCESS',
  'DELETE_EMAIL_FAIL',

  'SET_DEFAULT_PHONE',
  'SET_DEFAULT_PHONE_SUCCESS',
  'SET_DEFAULT_PHONE_FAIL',

  'SET_DEFAULT_EMAIL',
  'SET_DEFAULT_EMAIL_SUCCESS',
  'SET_DEFAULT_EMAIL_FAIL',
];
