import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import utc from 'dayjs/plugin/utc';
import first from 'lodash/first';
import DATE_FORMATS from 'shared/constants/DATE_FORMATS';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

export const isPasteDate = (date: string) => dayjs(date, DATE_FORMATS.DATE_TIME).isBefore(dayjs());

export const isNextYear = (date: string) => dayjs(date, DATE_FORMATS.DATE).isAfter(dayjs(), 'year');

export const dateWithoutOffset = (date: Date | string) => dayjs.utc(date).format();

export const combineDateAndTime = (dateDayjs, timeDayjs) => {
  const newDayjs = dateDayjs.clone();

  return newDayjs
    .hour(timeDayjs.hour())
    .minute(timeDayjs.minute())
    .second(timeDayjs.second())
    .millisecond(timeDayjs.millisecond());
};

export const generateDatesArray = (
  dateStart: dayjs.Dayjs,
  dateEnd: dayjs.Dayjs,
  times: Array<{ time_start: dayjs.Dayjs; time_end: dayjs.Dayjs }>,
) => {
  const datesArray: Array<{ startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }> = [];
  const filteredTimes = times.filter(
    (o) => typeof o.time_start !== 'undefined' && typeof o.time_end !== 'undefined',
  );

  const firstDate = first<dayjs.Dayjs>(
    filteredTimes.map(({ time_start }) => combineDateAndTime(dateStart.clone(), time_start)),
  );
  const lastDate = first<dayjs.Dayjs>(
    filteredTimes.map(({ time_end }) => combineDateAndTime(dateEnd.clone(), time_end)),
  );
  let clonedStartDate = firstDate?.clone();

  while (clonedStartDate?.utc().isSameOrBefore(lastDate?.utc())) {
    filteredTimes.forEach(({ time_start, time_end }) => {
      const startDate = combineDateAndTime(clonedStartDate?.clone(), time_start);
      const endDate = combineDateAndTime(clonedStartDate?.clone(), time_end);
      datesArray.push({ startDate, endDate });
    });

    clonedStartDate = clonedStartDate.add(1, 'day');
  }

  return datesArray;
};
