import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import sortBy from 'lodash/sortBy';
import merge from 'lodash/merge';
import axios from 'axios';
import type { Widget } from './widgets.types';

const api = axios.create({
  baseURL: 'https://api-widget.pbilet.org/api',
  responseType: 'json',
  headers: {
    Authorization: `Bearer ${process.env.WIDGET_API_TOKEN}`,
  },
});

export interface WidgetsState {
  data: {
    [key: string]: Widget;
  };
  ids: Array<number>;
  meta?: {
    total: number;
    page: number;
    limit: number;
    last_page: number;
    count: number;
  };
  shouldMerge: boolean;
  state: {
    isLoading: boolean;
    isLoadingMore: boolean;
    isFail: boolean;
  };
}

const initialState: WidgetsState = {
  data: {},
  ids: [],
  meta: null,
  shouldMerge: false,
  state: {
    isLoading: true,
    isLoadingMore: false,
    isFail: false,
  },
};

export const fetchWidgets = createAsyncThunk('widgets_v2/fetchWidgets', async (params: any = {}) => {
  const response = await api.get('/lookup/widget_landing', {
    params,
  });

  const { results, ...meta } = response.data.data;

  const scheme = new schema.Entity('data', {});
  const listScheme = new schema.Array(scheme);
  const normalized = normalize(results, listScheme);

  return { normalized, meta };
});

export const fetchWidget = createAsyncThunk(
  'widgets_v2/fetchWidget',
  async ({ id, params = {} }: any, { rejectWithValue }) => {
    try {
      const response = await api.get(`/widget/landing/${id}`, {
        params,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteWidget = createAsyncThunk<any, any>('widgets_v2/deleteWidget', (id: number) => {
  return api.delete(`/widget/landing/${id}`);
});

export const createWidget = createAsyncThunk<any, any>(
  'widgets_v2/createWidget',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await api.post('/widget/landing', data);

      return response.data as Widget;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const editWidget = createAsyncThunk<any, any>(
  'widgets_v2/editWidget',
  async ({ id, lang = 'ru', ...rest }: any, { rejectWithValue }) => {
    try {
      const response = await api.patch(`/widget/landing/${id}`, rest, {
        params: {
          lang,
        },
      });

      return response.data as Widget;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const widgetsSlice = createSlice({
  name: 'widgets_v2',
  initialState,
  reducers: {
    fetchMoreWidgets: (state) => {
      state.shouldMerge = true;
      state.state.isLoadingMore = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWidgets.pending, (state) => {
      const { shouldMerge } = state;
      state.data = shouldMerge ? state.data : {};
      state.ids = shouldMerge ? state.ids : [];
      state.meta = shouldMerge ? state.meta : null;
      state.state = {
        isLoading: !shouldMerge,
        isLoadingMore: shouldMerge,
        isFail: false,
      };
    });
    builder.addCase(fetchWidgets.fulfilled, (state, action) => {
      const {
        normalized: { entities, result },
        meta,
      } = action.payload;
      const { shouldMerge } = state;

      state.data = shouldMerge ? merge(state.data, entities.data) : entities.data || {};
      state.ids = shouldMerge ? [...state.ids, ...result] : result;
      state.meta = meta;
      state.shouldMerge = false;
      state.state = {
        isLoading: false,
        isLoadingMore: false,
        isFail: false,
      };
    });
    builder.addCase(editWidget.fulfilled, (state, action) => {
      function updateWidgets(widgets, data) {
        return widgets.map((widget) => {
          if (widget.id === data.id) {
            return {
              ...widget,
              ...data,
            };
          }

          return widget;
        });
      }

      const data = action.payload;

      state.data = state.ids.reduce((acc, id) => {
        const widget = state.data[id];

        if (widget.children) {
          const children = widget.children.map((child) => ({
            ...child,
            widgets: updateWidgets(child.widgets, data),
          }));

          acc[id] = {
            ...widget,
            ...(widget.widgets && { widgets: updateWidgets(widget.widgets, data) }),
            children,
          };

          return acc;
        }

        acc[id] = {
          ...widget,
          widgets: updateWidgets(widget.widgets, data),
        };

        return acc;
      }, {});
    });
  },
});

export const { fetchMoreWidgets } = widgetsSlice.actions;

const widgetsSelector = (state) => state.widgetv2.data;
const widgetsIdsSelector = (state) => state.widgetv2.ids;

export const sortedWidgetsSelector = createSelector([widgetsIdsSelector, widgetsSelector], (ids, widgets) =>
  ids.reduce((acc, id) => {
    const widget = widgets[id];

    if (widget.children) {
      acc[id] = {
        ...widget,
        children: sortBy(widget.children, (child) => new Date(`${child.date_start} ${child.time_start}`)),
      };

      return acc;
    }

    acc[id] = widget;

    return acc;
  }, {}),
);

export default widgetsSlice.reducer;
