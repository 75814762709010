import { HYDRATE } from 'next-redux-wrapper';
import { actionTypes as accountActionTypes } from '../account/actionTypes';
import { createPermissionObject } from 'shared/helpers/permissions';

const INITIAL_STATE = null;

export const permissionsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case accountActionTypes.SET_CURRENT_USER: {
      const { permissions, admin_permissions: adminPermissions } = action.payload.data;
      const currentUserPermission = permissions ? createPermissionObject(permissions) : null;
      const currentAdminPermission = adminPermissions ? createPermissionObject(adminPermissions) : null;

      return {
        ...currentUserPermission,
        ...currentAdminPermission,
      };
    }
    case accountActionTypes.LOAD_SUCCESS: {
      const { permissions, admin_permissions: adminPermissions } = action.payload.data.data;
      const currentUserPermission = permissions ? createPermissionObject(permissions) : null;
      const currentAdminPermission = adminPermissions ? createPermissionObject(adminPermissions) : null;

      return {
        ...currentUserPermission,
        ...currentAdminPermission,
      };
    }
    case HYDRATE:
      return {
        ...state,
        ...action.payload.permissions,
      };
    default:
      return state;
  }
};
