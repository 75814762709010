import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD',
    'LOAD_SUCCESS',
    'LOAD_FAIL',
    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_FAIL',
    'DELETE',
    'DELETE_SUCCESS',
    'DELETE_FAIL',
    'UPDATE',
  ],
  {
    prefix: 'HALL_LAYOUTS.',
  },
);
