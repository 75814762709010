import { actionTypes } from './actionTypes';

const INITIAL_STATE = {
  data: null,
  provider: null,
  state: {
    isLoading: false,
    isFail: false,
  },
};

export const paymentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        data: null,
        state: {
          isLoading: true,
          isFail: false,
        },
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    case actionTypes.LOAD_PROVIDER_SUCCESS:
      return {
        ...state,
        provider: action.payload.data,
      };
    case actionTypes.CREATE:
      return {
        ...state,
        state: {
          isLoading: true,
          isFail: false,
        },
      };
    case actionTypes.CREATE_SUCCESS: {
      const { data } = action.meta.previousAction.payload.request;

      return {
        ...state,
        data: {
          ...state.data,
          ...data,
        },
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    }
    case actionTypes.DELETE:
      return {
        ...state,
        state: {
          isLoading: true,
          isFail: false,
        },
      };
    case actionTypes.DELETE_SUCCESS:
      return {
        ...state,
        data: {},
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    case actionTypes.CLEAR_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
