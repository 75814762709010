import { actionTypes } from './actionTypes';
import { userAuditSources } from './sources';

const loadUserAudit = () => ({
  type: actionTypes.LOAD,
  payload: {
    request: {
      url: userAuditSources.root,
    },
  },
});

const loadMoreUserAudit = (page: number | string) => ({
  type: actionTypes.LOAD_MORE,
  payload: {
    request: {
      url: userAuditSources.root,
      params: {
        page,
      },
    },
  },
});

export { loadUserAudit, loadMoreUserAudit };
