import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import { HYDRATE } from 'next-redux-wrapper';
import { actionTypes } from './actionTypes';
import PAGES from 'shared/constants/PAGES';
import TABLES from 'shared/constants/TABLES';
import converToCamelCase, { POSTFIXS } from 'shared/helpers/convertToCamelCase';
import { actionTypes as announcementActionTypes } from 'entities/announcement';
import TABLES_ROW_ACTIONS from 'shared/constants/TABLES_ROW_ACTIONS';

const createReducerData = (defaultValue, postfix) =>
  reduce(
    { ...PAGES, ...TABLES },
    (acc, value) => {
      acc[converToCamelCase({ str: value, postfix })] = defaultValue;
      return acc;
    },
    {},
  );

const INITIAL_STATE: any = {
  filterbars: createReducerData(false, POSTFIXS.PAGE_FILTER_IS_OPEN),
  selectedRows: createReducerData(
    {
      allSelected: false,
      ids: [],
      errors: {},
      showOnlyErrors: false,
    },
    POSTFIXS.TABLE_SELECTED_ROWS,
  ),
  queries: createReducerData({}, POSTFIXS.PAGE_SAVED_QUERIES),
  expandedRows: createReducerData(
    {
      allSelected: false,
      ids: [],
    },
    POSTFIXS.PAGE_EXPANDED_ROWS,
  ),
  allRowsExpanded: createReducerData(false, POSTFIXS.PAGE_ALL_ROWS_EXPANDED),
  disabled: createReducerData(false, POSTFIXS.TABLE_DISABLED),
};

export function tablesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_BAR_STATE:
      return {
        ...state,
        filterbars: {
          ...state.filterbars,
          [action.payload.data]: !state.filterbars[action.payload.data],
        },
      };

    case actionTypes.SET_BAR_STATE:
      return {
        ...state,
        filterbars: action.payload.data,
      };

    case actionTypes.SELECT_ROW: {
      const { value, name, rowsCount, rowAction } = action.payload.data;
      const stateRows = state[rowAction][name];
      const stateAllselected = stateRows.allSelected;
      const ids = includes(stateRows.ids, value)
        ? stateRows.ids.filter((item) => item !== value)
        : [...stateRows.ids, value];
      let allSelected = false;

      if (ids.length < rowsCount && stateAllselected) {
        allSelected = false;
      }

      if (ids.length === rowsCount && !stateAllselected) {
        allSelected = true;
      }

      return {
        ...state,
        [rowAction]: {
          ...state[rowAction],
          [name]: {
            ...stateRows,
            ids,
            allSelected,
          },
        },
      };
    }

    case actionTypes.SELECT_ALL_ROWS: {
      const { name, ids = [], rowAction, ignoredIds = [] } = action.payload.data;
      const stateRows = state[rowAction][name];

      if (rowAction === TABLES_ROW_ACTIONS.EXPAND && stateRows.allSelected) {
        return {
          ...state,
          [rowAction]: {
            ...state[rowAction],
            [name]: {
              ...stateRows,
            },
          },
        };
      }

      return {
        ...state,
        [rowAction]: {
          ...state[rowAction],
          [name]: {
            ...stateRows,
            ids: stateRows.allSelected ? [] : ids.filter((item) => !includes(ignoredIds, item)),
            allSelected: !stateRows.allSelected,
            errors: stateRows.allSelected ? {} : stateRows.errors,
          },
        },
      };
    }

    case actionTypes.UNSELECT_ALL_ROWS: {
      const { name, rowAction } = action.payload.data;

      switch (rowAction) {
        case TABLES_ROW_ACTIONS.SELECT:
          return {
            ...state,
            [TABLES_ROW_ACTIONS.SELECT]: {
              ...state[TABLES_ROW_ACTIONS.SELECT],
              [name]: {
                ids: [],
                allSelected: false,
                errors: {},
              },
            },
          };

        case TABLES_ROW_ACTIONS.EXPAND:
          return {
            ...state,
            [TABLES_ROW_ACTIONS.EXPAND]: {
              ...state[TABLES_ROW_ACTIONS.EXPAND],
              [name]: {
                ids: [],
                allSelected: false,
              },
            },
          };

        default:
          return null;
      }
    }

    case actionTypes.SET_ROWS_ERRORS: {
      const { name, errors } = action.payload.data;
      const stateRows = state.selectedRows[name];

      return {
        ...state,
        selectedRows: {
          ...state.selectedRows,
          [name]: {
            ...stateRows,
            errors,
          },
        },
      };
    }

    case actionTypes.SELECT_MANY_ROWS: {
      const { name, ids, rowsCount } = action.payload.data;
      const stateRows = state.selectedRows[name];
      const stateAllselected = stateRows.allSelected;
      let allSelected = false;

      if (ids.length < rowsCount && stateAllselected) {
        allSelected = false;
      }

      if (ids.length === rowsCount && !stateAllselected) {
        allSelected = true;
      }

      return {
        ...state,
        selectedRows: {
          ...state.selectedRows,
          [name]: {
            ...stateRows,
            ids,
            allSelected,
          },
        },
      };
    }

    case actionTypes.UPDATE_QUERIES: {
      const { name, query } = action.payload.data;

      return {
        ...state,
        queries: {
          ...state.queries,
          [name]: query,
        },
      };
    }

    case actionTypes.SET_QUERIES:
      return {
        ...state,
        queries: action.payload.data,
      };

    case announcementActionTypes.SET_SELECTED_ANNOUNCEMENT: {
      return {
        ...state,
        expandedRows: {
          ...state.expandedRows,
          poolDetailPageExpandedRows: {
            ...state.expandedRows.poolDetailPageExpandedRows,
            ids: action.payload.data.map((item) => item.id),
          },
        },
      };
    }

    case announcementActionTypes.SELECT_SEAT: {
      const { shouldClear, announcement } = action.payload;

      if (shouldClear) {
        return {
          ...state,
          expandedRows: {
            ...state.expandedRows,
            poolDetailPageExpandedRows: {
              ...state.expandedRows.poolDetailPageExpandedRows,
              ids: [announcement.id],
            },
          },
        };
      }

      return state;
    }

    case actionTypes.EXPAND_ALL_ROWS:
      return {
        ...state,
        allRowsExpanded: {
          ...state.allRowsExpanded,
          [action.payload.data]: !state.allRowsExpanded[action.payload.data],
        },
      };

    case actionTypes.SET_EXPANDED_ALL_ROWS:
      return {
        ...state,
        allRowsExpanded: action.payload.data,
      };

    case actionTypes.TOGGLE_SHOW_ONLY_ERRORS:
      return {
        ...state,
        selectedRows: {
          ...state.selectedRows,
          [action.payload.data]: {
            ...state.selectedRows[action.payload.data],
            showOnlyErrors: !state.selectedRows[action.payload.data].showOnlyErrors,
          },
        },
      };

    case actionTypes.SET_DISABLED: {
      const { name, value } = action.payload.data;

      return {
        ...state,
        disabled: {
          ...state.disabled,
          [name]: value,
        },
      };
    }

    case HYDRATE: {
      return {
        ...state,
        ...action.payload.tables,
      };
    }

    default:
      return state;
  }
}
