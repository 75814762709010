import { actionTypes } from './actionTypes';

export const parsersVenuesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.EDIT_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: state.data.map((item) => (item.id === data.id ? data : item)),
      };
    }

    case actionTypes.UNLINK_SUCCESS: {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.config.id) {
            return {
              ...item,
              external_name: null,
              external_id: null,
            };
          }

          return item;
        }),
      };
    }

    default:
      return undefined;
  }
};
