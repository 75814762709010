import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD_DATA',
    'LOAD_DATA_SUCCESS',
    'LOAD_DATA_FAIL',

    'LOAD_MORE',

    'LOAD_DETAIL',
    'LOAD_DETAIL_SUCCESS',
    'LOAD_DETAIL_FAIL',

    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_FAIL',

    'DELETE',
    'DELETE_SUCCESS',
    'DELETE_FAIL',

    'EDIT',
    'EDIT_SUCCESS',
    'EDIT_FAIL',
  ],
  {
    prefix: 'WIDGET.',
  },
);
