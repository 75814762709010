import { actionTypes, loadDetailInvoice } from 'entities/invoices';

const invoiceMiddleware = (store) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case actionTypes.ASSIGN_TO_ME_SUCCESS:
    case actionTypes.ASSIGN_USER_SUCCESS:
    case actionTypes.SET_AWAIT_SUCCESS:
    case actionTypes.EDIT_USER_CUSTOMER_SUCCESS:
    case actionTypes.REJECT_INVOICE_SUCCESS:
    case actionTypes.EDIT_INVOICE_EVENT_SUCCESS:
    case actionTypes.CREATE_ORDER_SUCCESS: {
      const { id } = action.meta.previousAction.payload;

      store.dispatch(loadDetailInvoice(id));
      break;
    }
    default:
  }
};

export default invoiceMiddleware;
