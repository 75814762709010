import includes from 'lodash/includes';
import { actionTypes } from 'entities/announcement';
import { actionTypes as orderActionTypes } from 'entities/order';
import { selectSeat } from 'shared/helpers/tickets';
import STORAGE_TOKENS from 'shared/constants/STORAGE_TOKENS';

const INITIAL_STATE = [];

const updateAnnouncementPrice = ({ id, newPrice, state }) => {
  const newState = state.map((item) => {
    if (item.id === id) {
      return {
        ...item,
        total_price: newPrice,
      };
    }

    return item;
  });

  localStorage.setItem(STORAGE_TOKENS.SELECTED_ANNOUNCEMENTS, JSON.stringify(newState));

  return newState;
};

export const selectedAnnouncementsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SELECT_SEAT:
      return selectSeat(action.payload, state);

    case actionTypes.DELETE_SELECTED_TICKET:
      return state.filter((announcement) => announcement.id !== action.payload.id);

    case actionTypes.CLEAR_SELECTED_TICKETS:
      return [];

    case actionTypes.UPDATE_SELECTED_TICKETS:
      return state.reduce((acc, announcement) => {
        const { checks } = action.payload;

        const currentCheck = checks.find(
          (availableTicket) => availableTicket.announcement === announcement.id,
        );

        if (!currentCheck) {
          acc.push(announcement);

          return acc;
        }

        const { tickets: disabledTickets } = currentCheck;

        if (Array.isArray(disabledTickets)) {
          const disabledTicketsIds = new Set(disabledTickets.map((ticket) => ticket.id));
          const availableTickets = announcement.selectedTickets.filter(
            (ticket) => !disabledTicketsIds.has(ticket.id),
          );

          if (availableTickets.length) {
            acc.push({
              ...announcement,
              selectedTickets: availableTickets,
            });
          }
        }

        if (!Array.isArray(disabledTickets) && disabledTickets) {
          acc.push({
            ...announcement,
            selectedTickets: disabledTickets,
          });
        }

        return acc;
      }, []);

    case orderActionTypes.CREATE_SUCCESS:
    case orderActionTypes.ADD_TICKETS_FROM_POOL_SUCCESS:
      return [];

    case actionTypes.SET_SELECTED_ANNOUNCEMENT:
      return action.payload.data;

    case actionTypes.UPDATE_TICKET_PRICE: {
      const { id, newPrice } = action.payload;

      if (state) {
        const newState = updateAnnouncementPrice({ id, newPrice, state });

        return newState;
      }

      return state;
    }

    case actionTypes.EDIT_SUCCESS: {
      const { id, data } = action.meta.previousAction.payload.request;

      if (state) {
        const newState = updateAnnouncementPrice({ id, newPrice: data.total_price, state });

        return newState;
      }

      return state;
    }

    case actionTypes.BULK_PRICE_UPDATE_SUCCESS: {
      const { ids, price } = action.payload.config;

      if (state) {
        let changedAnnouncements = ids;

        const newState = state.map((item) => {
          if (includes(changedAnnouncements, item.id)) {
            changedAnnouncements = changedAnnouncements.filter(
              (announcement) => announcement !== announcement.id,
            );

            return {
              ...item,
              total_price: price,
            };
          }

          return item;
        });

        localStorage.setItem(STORAGE_TOKENS.SELECTED_ANNOUNCEMENTS, JSON.stringify(newState));

        return newState;
      }

      return state;
    }

    default:
      return state;
  }
};
