const ANNOUNCEMENTS_TYPES_ENUM = {
  PREORDER: 0,
  IN_STOCK: 1,
};

const ANNOUNCEMENTS_TYPES = {
  0: 'Предзаказ',
  1: 'В наличии',
};

const ANNOUNCEMENT_TYPES_OPTIONS = [
  { id: 0, value: 0, label: ANNOUNCEMENTS_TYPES[0] },
  { id: 1, value: 1, label: ANNOUNCEMENTS_TYPES[1] },
];

export { ANNOUNCEMENTS_TYPES, ANNOUNCEMENT_TYPES_OPTIONS, ANNOUNCEMENTS_TYPES_ENUM };
