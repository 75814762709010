import Sidebar from 'components/Sidebar';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import { toggleSidebarState } from 'entities/layout';
import LOCALES from 'shared/constants/LOCALES';
import { withTranslation } from '../../i18n';

const mapStateToProps = (state) => ({
  data: state.account.data,
  sidebarIsOpen: state.layout.sidebarIsOpen,
  permissions: state.permissions,
  isOwner: state.account.data.isOwner,
});

const mapDispatchToProps = {
  toggleSidebarState,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation(LOCALES.SIDEBAR)(Sidebar)),
);
