import Router from 'next/router';
import { actionTypes } from 'entities/announcement';
import routes from 'shared/helpers/routes';

export const userAnnouncementDetail = (state, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: state.data.map((item) => (item.id === data.id ? data : item)),
      };
    }

    case actionTypes.UPDATE_TICKET_PRICE: {
      const { id, newPrice } = action.payload;

      return {
        ...state,
        data: state.data.map((announcement) =>
          announcement.id === id
            ? {
                ...announcement,
                total_price: newPrice,
              }
            : announcement,
        ),
      };
    }

    case actionTypes.DELETE_SUCCESS: {
      const data = state.data.filter((item) => item.id !== action.payload.config.id);

      if (data.length === 0) {
        Router.replace(routes.announcements).catch((err) => new Error(err));
      }

      return {
        ...state,
        data,
        meta: {
          ...state.meta,
          count: state.meta.count - 1,
        },
      };
    }

    case actionTypes.LOAD_DETAIL_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: state.data.map((item) => (item.id === data.id ? data : item)),
      };
    }

    case actionTypes.LOAD_DETAIL_FAIL: {
      if (action.error.response.status === 404) {
        const data = state.data.filter((item) => item.id !== action.error.config.id);

        if (data.length === 0) {
          Router.replace(routes.announcements).catch((err) => new Error(err));
        }

        return {
          ...state,
          data,
          meta: {
            ...state.meta,
            count: state.meta.count - 1,
          },
        };
      }

      return {
        ...state,
      };
    }

    case actionTypes.EDIT_SUCCESS: {
      const { id } = action.payload.config;

      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              shouldUpdate: true,
            };
          }

          return item;
        }),
      };
    }

    default:
      return undefined;
  }
};
