export const accountSources = {
  me: 'accounts/users/me',
  emails: 'accounts/users/emails',
  phones: 'accounts/users/phones',
  members: 'accounts/users/members',
  emailsDetail: (id: number | string) => `accounts/users/emails/${id}`,
  phonesDetail: (id: number | string) => `accounts/users/phones/${id}`,
  defaultEmail: (id: number | string) => `accounts/users/emails/${id}/set-default`,
  defaultPhone: (id: number | string) => `accounts/users/phones/${id}/set-default`,
};
