import { actionTypes } from './actionTypes';
import { draftsSources } from './sources';

const loadDrafts = () => ({
  type: actionTypes.LOAD_DRAFTS,
  payload: {
    request: {
      url: draftsSources.root,
      params: {
        limit: 250,
      },
    },
  },
});

const createDraft = (data: any) => ({
  type: actionTypes.CREATE_DRAFT,
  payload: {
    request: {
      method: 'POST',
      url: draftsSources.root,
      data,
    },
  },
});

const editDraft = (id: number | string, data: any) => ({
  type: actionTypes.EDIT_DRAFT,
  payload: {
    request: {
      method: 'PATCH',
      url: draftsSources.detail(id),
      data,
      id,
    },
  },
});

const deleteDraft = ({ id }: { id: number | string }) => ({
  type: actionTypes.DELETE_DRAFT,
  payload: {
    request: {
      method: 'DELETE',
      url: draftsSources.detail(id),
      id,
    },
  },
});

export { loadDrafts, createDraft, editDraft, deleteDraft };
