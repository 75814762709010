const TICKET_TYPES = {
  1: 'Электронный',
  2: 'Бумажный',
  3: 'Абонемент',
};

const TICKET_TYPES_VALUES = {
  ELECTRONIC: 1,
  PAPER: 2,
  SUBSCRIPTION: 3,
};

const TICKET_SIGNS = {
  meta_personal: 'Именной',
  meta_non_returnable: 'Невозврат',
};

const TICKET_TYPES_ICONS = {
  1: '/static/Table/ticket-electronic.svg',
  2: '/static/Table/ticket-paper.svg',
  3: '/static/Table/ticket-subscription.svg',
};

const TICKET_SIGNS_ICONS = {
  meta_personal: '/static/Table/ticket-personal.svg',
  meta_non_returnable: '/static/Table/ticket-non-returnable.svg',
};

const TICKET_TYPES_OPTIONS = [
  { id: 1, value: 1, name: TICKET_TYPES[1] },
  { id: 2, value: 2, name: TICKET_TYPES[2] },
  { id: 3, value: 3, name: TICKET_TYPES[3] },
];

const TICKET_STATUSES_VALUES = {
  NEW: 1,
  HOLD: 2,
  DELIVERY: 3,
  REFUND: 4,
  FINISH: 5,
  SOLD: 6,
};

const TICKET_STATUS = {
  IN_SALE: 1,
  BURNED: 2,
  RETURNED: 3,
  SOLD: 4,
  IN_ORDER: 999,
  DELETED: 998,
};

const TICKET_HIGHLIGHT = {
  IS_FOUND: 'IS_FOUND',
  IS_PREORDER: 'IS_PREORDER',
  IS_SOLD: 'IS_SOLD',
  IS_NOT_SOLD: 'IS_NOT_SOLD',
  BOOKED_BY_ME: 'BOOKED_BY_ME',
  CANCELLED_PURCHASE: 'CANCELLED_PURCHASE',
  BOOKED_ON_AN_EXCHANGE: 'BOOKED_ON_AN_EXCHANGE',
  AVAILABLE_FOR_SALE: 'AVAILABLE_FOR_SALE',
  AVAILABLE_FOR_PURCHASE: 'AVAILABLE_FOR_PURCHASE',
};

export {
  TICKET_TYPES,
  TICKET_TYPES_OPTIONS,
  TICKET_TYPES_ICONS,
  TICKET_STATUSES_VALUES,
  TICKET_TYPES_VALUES,
  TICKET_SIGNS,
  TICKET_SIGNS_ICONS,
  TICKET_HIGHLIGHT,
  TICKET_STATUS,
};
