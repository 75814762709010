import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface CSSProps {
  active?: boolean;
  theme?: {
    sidebarIsOpen?: boolean;
  };
  isNew?: boolean;
}

export default {
  Item: styled.div<CSSProps>`
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 48px;
    padding: 10px 16px 10px 22px;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;

    &::after {
      content: '';
      display: ${(props) => (props.active ? 'block' : 'none')};
      position: absolute;
      width: 4px;
      height: 40px;
      left: 0;
      top: 0;
      background: ${(props) => (props.active ? '#FFDA00' : '#999')};
      border-radius: 0px 4px 4px 0px;
    }

    &:hover::after {
      display: block;
    }

    &:hover > div:nth-child(2) {
      color: #fff;
    }

    &:hover img {
      opacity: 1;
    }
  `,

  Icon: styled.span<CSSProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 20px;
    min-width: 20px;
    width: 20px;
    max-height: 20px;
    min-height: 20px;
    height: 20px;
    border-radius: 4px;
    filter: ${(props) => (props.active ? 'none' : 'grayscale(100%)')};

    img {
      opacity: ${(props) => (props.active ? 1 : 0.8)};
    }
  `,

  Text: styled.div<CSSProps>`
    position: absolute;
    left: 64px;
    opacity: ${(props) => (props.theme.sidebarIsOpen ? '1' : '0')};
    font-size: 16px;
    color: ${({ active }) => (active ? '#fff' : 'rgba(255, 255, 255, 0.64)')};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media (max-width: ${BREAKPOINTS.XL}) {
      opacity: 1;
    }
  `,

  Count: styled.div<CSSProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 22px;
    background-color: rgba(255, 255, 255, 0.64);
    padding: 0px 4px;
    border-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    line-height: 1;
    font-size: 12px;
    line-height: 16px;

    ${(props) =>
      !props.theme.sidebarIsOpen &&
      css`
        width: 6px;
        height: 6px;
        right: 8px;
        padding: 0;
        background-color: #ffda00;
        text-indent: -99999px;
      `}

    ${({ active, theme, isNew }) =>
      active &&
      theme.sidebarIsOpen &&
      css`
        background-color: ${isNew ? '#FFDA00' : '#fff'};
      `}
  `,
};
