import { actionTypes } from './actionTypes';
import { actionTypes as orderActionTypes } from 'entities/order';
import { dateWithoutOffset } from 'shared/helpers/date';

const INITIAL_STATE = {
  data: [],
  state: {
    isLoading: false,
    isFail: false,
  },
};

export const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case orderActionTypes.CLOSE:
      return {
        ...state,
        data: [],
        state: {
          isLoading: false,
          isFail: false,
        },
      };

    case actionTypes.LOAD_COMMENTS:
      return {
        ...state,
        state: {
          isLoading: true,
          isFail: false,
        },
      };

    case actionTypes.LOAD_COMMENTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data.results,
        state: {
          isLoading: false,
          isFail: false,
        },
      };

    case actionTypes.LOAD_COMMENTS_FAIL:
      return {
        ...state,
        state: {
          isLoading: false,
          isFail: true,
        },
      };

    case actionTypes.LOAD_COMMENT: {
      const { data } = action.payload;
      const commentIsExist = state.data.find(
        (comment) =>
          dateWithoutOffset(comment.created) === dateWithoutOffset(data.created) &&
          comment.comment === data.comment,
      );

      if (!commentIsExist) {
        return {
          ...state,
          data: [...state.data, data],
        };
      }
      return state;
    }

    case actionTypes.ADD_COMMENT_SUCCESS: {
      const { user, user_id: userID } = action.payload.data;

      return {
        ...state,
        data: [...state.data, { ...action.payload.data, user_id: user || userID, author: 'You' }],
      };
    }

    case actionTypes.ADD_NEW_ATTACHMENTS:
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };

    case actionTypes.CLEAR_COMMENTS:
      return {
        ...state,
        data: [],
      };

    case actionTypes.LOAD_NEW_STATUS:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      };

    default:
      return state;
  }
};
