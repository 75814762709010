import { actionTypes as announcementActionTypes } from 'entities/announcement';

export const poolDetailReducer = (state, action) => {
  switch (action.type) {
    case announcementActionTypes.UPDATE_SELECTED_TICKETS: {
      const { checks } = action.payload;

      return {
        ...state,
        data: state.ids.reduce((acc, id) => {
          const announcement = state.data[id];
          const existingAnnouncement = checks.find((item) => item.announcement === announcement.id);

          if (existingAnnouncement) {
            if (Array.isArray(existingAnnouncement.tickets)) {
              acc[id] = {
                ...announcement,
                tickets: announcement.tickets.map((ticket) => {
                  const updatedTicket = existingAnnouncement.tickets.find((t) => t.id === ticket.id);

                  if (updatedTicket) {
                    return {
                      ...ticket,
                      ...updatedTicket,
                    };
                  }

                  return ticket;
                }),
              };

              return acc;
            }

            acc[id] = {
              ...announcement,
              count: existingAnnouncement.tickets,
            };

            return acc;
          }

          acc[id] = announcement;

          return acc;
        }, {}),
      };
    }

    case announcementActionTypes.UPDATE_TICKET_PRICE: {
      const { id, newPrice } = action.payload;

      return {
        ...state,
        data: state.ids.reduce((acc, curr) => {
          const announcement = state.data[curr];

          if (announcement.id === id) {
            acc[announcement.id] = {
              ...announcement,
              total_price: newPrice,
            };

            return acc;
          }

          acc[announcement.id] = announcement;

          return acc;
        }, {}),
      };
    }

    default:
      return undefined;
  }
};
