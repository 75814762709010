import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD_DATA',
    'LOAD_DATA_SUCCESS',
    'LOAD_DATA_FAIL',

    'LOAD_MORE',

    'LOAD_DETAIL',
    'LOAD_DETAIL_SUCCESS',
    'LOAD_DETAIL_FAIL',

    'UPDATE',
    'UPDATE_SUCCESS',
    'UPDATE_FAIL',

    'LOAD_PERMISSIONS',
    'LOAD_PERMISSIONS_SUCCESS',
    'LOAD_PERMISSIONS_FAIL',

    'LOAD_SOURCES',
    'LOAD_SOURCES_SUCCESS',
    'LOAD_SOURCES_FAIL',

    'UPDATE_SOURCES',
    'UPDATE_SOURCES_SUCCESS',
    'UPDATE_SOURCES_FAIL',

    'SET_VIEWERS_ACCESS',
    'SET_VIEWERS_ACCESS_SUCCESS',
    'SET_VIEWERS_ACCESS_FAIL',

    'APPROVE',
    'APPROVE_SUCCESS',
    'APPROVE_FAIL',

    'DEACTIVATE',
    'DEACTIVATE_SUCCESS',
    'DEACTIVATE_FAIL',

    'REACTIVATE',
    'REACTIVATE_SUCCESS',
    'REACTIVATE_FAIL',
  ],
  {
    prefix: 'MEMBERS_COMPANIES.',
  },
);
