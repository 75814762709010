const isAxiosRequest = (action) => action.payload && action.payload.request;

const requestMiddleware = () => (next) => (action) => {
  if (!isAxiosRequest(action)) {
    return next(action);
  }
  const { payload, type } = action;

  if (!type.endsWith('_SUCCESS') && !type.endsWith('_FAIL')) {
    const timestamp = new Date().getTime();

    const newAction = {
      ...action,
      payload: {
        ...payload,
        timestamp,
      },
    };

    return next(newAction);
  }

  return next(action);
};

export default requestMiddleware;
