const TEXT = {
  1: 'Новый',
  2: 'В работе',
  3: 'Игнор-тся',
  4: 'Пауза',
};

const COLOR = {
  1: 'primary',
  2: 'success',
  3: 'danger',
  4: 'warning',
};

const PARSERS_STATUS_OPTIONS = [
  { id: 0, value: 1, label: TEXT[1] },
  { id: 1, value: 2, label: TEXT[2] },
  { id: 2, value: 3, label: 'Игнорируется' },
  { id: 3, value: 4, label: TEXT[4] },
];

const ENUM = {
  NEW: 1,
  IN_WORK: 2,
  IGNORED: 3,
  PAUSE: 4,
};

export { TEXT, COLOR, PARSERS_STATUS_OPTIONS, ENUM };
