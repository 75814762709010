import { actionTypes } from './actionTypes';

const INITIAL_STATE = {
  data: {},
  emails: [],
  phones: [],
  state: {
    isLoading: true,
    isFail: false,
  },
};

export const companyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        data: {},
        state: {
          isLoading: true,
          isFail: false,
        },
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    case actionTypes.LOAD_FAIL:
      return {
        ...state,
        data: {},
        state: {
          isLoading: false,
          isFail: true,
        },
      };

    case actionTypes.CREATE:
      return {
        ...state,
        data: {},
      };
    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
      };
    case actionTypes.CREATE_FAIL:
      return {
        ...state,
        data: {},
      };

    default:
      return state;
  }
};
