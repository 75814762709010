import { actionTypes } from './actionTypes';
import { orderSources } from './sources';

const createOrder = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.root,
      data,
    },
    withNotification: true,
  },
});

const loadSale = (id: number | string) => ({
  type: actionTypes.LOAD_SALE,
  payload: {
    request: {
      url: orderSources.detail(id),
      params: {
        my_sales: false,
      },
    },
  },
});

const loadSalesMore = (params = {}) => ({
  type: actionTypes.LOAD_SALES_MORE,
  payload: {
    request: {
      url: orderSources.root,
      params: {
        ...params,
        my_sales: false,
      },
    },
  },
});

const loadPurchasesMore = (params = {}) => ({
  type: actionTypes.LOAD_PURCHASES_MORE,
  payload: {
    request: {
      url: orderSources.root,
      params: {
        ...params,
        my_sales: true,
      },
    },
  },
});

const openOrder = (id: number | string, isSales?: boolean) => ({
  type: actionTypes.OPEN,
  payload: {
    id,
    isSales,
  },
});

const closeOrder = () => ({
  type: actionTypes.CLOSE,
});

const finishOrder = (id: number | string) => ({
  type: actionTypes.FINISH_ORDER,
  payload: {
    request: {
      url: orderSources.finish(id),
    },
    id,
  },
});

const cancelOrder = (id: number | string, data: any) => ({
  type: actionTypes.CANCEL_ORDER,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.reject(id),
      data,
    },
    id,
  },
});

const loadOrder = (id: number | string, params = {}) => ({
  type: actionTypes.LOAD_ORDER,
  payload: {
    request: {
      url: orderSources.detail(id),
      params,
    },
  },
});

const assignUser = (id: number | string, data: any) => ({
  type: actionTypes.ASSIGN_USER,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.assign(id),
      data,
    },
    id,
  },
});

const assignToMe = (id: number | string) => ({
  type: actionTypes.ASSIGN_USER,
  payload: {
    request: {
      url: orderSources.assignToMe(id),
    },
    id,
  },
});

const setDiscount = (id: number | string, data: any) => ({
  type: actionTypes.SET_DISCOUNT,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.discount(id),
      data,
    },
    id,
  },
});

const deleteDiscount = (id: number | string, data: any) => ({
  type: actionTypes.DELETE_DISCOUNT,
  payload: {
    request: {
      method: 'DELETE',
      url: orderSources.discount(id),
      data,
    },
    id,
  },
});

const deleteTickets = (id: number | string, data: any) => ({
  type: actionTypes.DELETE_TICKETS,
  payload: {
    request: {
      method: 'DELETE',
      url: orderSources.deleteTickets(id),
      data,
    },
    id,
  },
});

const deleteTicketsFromPool = (id: number | string, data: any) => ({
  type: actionTypes.DELETE_TICKETS,
  payload: {
    request: {
      method: 'DELETE',
      url: orderSources.deleteTicketsFromPool(id),
      data,
    },
    id,
  },
});

const addContext = (data: any) => ({
  type: actionTypes.ADD_CONTEXT,
  payload: {
    data,
  },
});

const removeContext = () => ({
  type: actionTypes.REMOVE_CONTEXT,
});

const addTicketsFromPool = (id: number | string, data: any) => ({
  type: actionTypes.ADD_TICKETS_FROM_POOL,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.addTicketsFromPool(id),
      data,
    },
    withNotification: true,
    id,
  },
});

const refundOrder = (id, data: any) => ({
  type: actionTypes.REFUND,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.refund(id),
      data,
    },
    id,
  },
});

const setExternalId = (id, data: any) => ({
  type: actionTypes.SET_EXTERNAL_ID,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.externalId(id),
      data,
    },
    id,
  },
});

const deleteExternalId = (id, data: any) => ({
  type: actionTypes.DELETE_EXTERNAL_ID,
  payload: {
    request: {
      method: 'DELETE',
      url: orderSources.externalId(id),
      data,
    },
    id,
  },
});

const bulkReject = (data: any) => ({
  type: actionTypes.BULK_REJECT,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.bulkReject,
      data: {
        orders: data.ids,
        message: data.message,
        reject_reason: data.reject_reason,
      },
    },
  },
});

const bulkAssignMe = (data: any) => ({
  type: actionTypes.BULK_ASSIGN_ME,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.bulkAssignMe,
      data: {
        orders: data.ids,
      },
    },
  },
});

const bulkAssignManager = (data: any) => ({
  type: actionTypes.BULK_ASSIGN_MANAGER,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.bulkAssignManager,
      data: {
        orders: data.ids,
        user_id: data.manager.value,
      },
    },
  },
});

const editUserCustomer = (id: number | string, data: any) => ({
  type: actionTypes.EDIT_USER_CUSTOMER,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.updateCustomerInfo(id),
      data,
    },
    id,
  },
});

const updateOperatorStake = (id: number | string, data: any) => ({
  type: actionTypes.EDIT_OPERATOR_STAKE,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.operatorStake(id),
      data,
    },
    id,
  },
});

const updateOutlay = (id: number | string, data: any) => ({
  type: actionTypes.EDIT_OUTLAY,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.outlay(id),
      data,
    },
    id,
  },
});

const loadRelated = (id: number | string) => ({
  type: actionTypes.LOAD_RELATED,
  payload: {
    request: {
      url: orderSources.related(id),
    },
  },
});

const loadCross = (id: number | string) => ({
  type: actionTypes.LOAD_CROSS,
  payload: {
    request: {
      url: orderSources.cross(id),
    },
  },
});

const returnOrderOnSale = (id: number | string) => ({
  type: actionTypes.RETURN_ORDER_ON_SALE,
  payload: {
    request: {
      url: orderSources.returnOrderOnSale(id),
    },
  },
});

const loadHistory = (id: number | string, params = {}) => ({
  type: actionTypes.LOAD_HISTORY,
  payload: {
    request: {
      url: orderSources.history(id),
      params,
    },
  },
});

const setTicketSearchStatus = (id: number | string) => ({
  type: actionTypes.SET_TICKET_SEARCH,
  payload: {
    request: {
      url: orderSources.setTicketSearch(id),
    },
    id,
  },
});

const saleTickets = (id: number | string, data: any) => ({
  type: actionTypes.SALE_TICKETS,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.saleTickets(id),
      data,
    },
    id,
  },
});

const loadViewHistory = (id: number | string, params = {}) => ({
  type: actionTypes.LOAD_VIEW_HISTORY,
  payload: {
    request: {
      url: orderSources.viewHistory(id),
      params,
    },
  },
});

const setSite = (id: number | string, data: any) => ({
  type: actionTypes.SET_SITE,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.setSite(id),
      data,
    },
  },
});

const setShowDeleteTicketsForm = (value: any) => ({
  type: actionTypes.SHOW_DELETE_TICKETS_FORM,
  payload: {
    value,
  },
});

const setShowDeleteTicketsAndPoolForm = (value: any) => ({
  type: actionTypes.SHOW_DELETE_TICKETS_AND_POOL_FORM,
  payload: {
    value,
  },
});

const updateOrderField = ({ id, name, value }) => ({
  type: actionTypes.UPDATE_FIELD,
  payload: {
    id,
    name,
    value,
  },
});

const addNotification = ({ id, comment, sendAt }) => ({
  type: actionTypes.ADD_NOTIFICATION,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.createNotification(id),
      data: {
        comment,
        send_at: sendAt,
      },
    },
  },
});

const updateRoistat = (id: number | string, data: any) => ({
  type: actionTypes.EDIT_ROISTAT,
  payload: {
    request: {
      method: 'PATCH',
      url: orderSources.roistat(id),
      data,
    },
    id,
  },
});

const updateOrderRefundRequest = (id: number | string, data) => ({
  type: actionTypes.REFUND_REQUEST,
  payload: {
    request: {
      method: 'PATCH',
      url: orderSources.refundRequest(id),
      data,
    },
    id,
  },
});

export {
  createOrder,
  loadSale,
  loadSalesMore,
  loadPurchasesMore,
  openOrder,
  closeOrder,
  finishOrder,
  cancelOrder,
  loadOrder,
  assignUser,
  assignToMe,
  setDiscount,
  deleteDiscount,
  deleteTickets,
  deleteTicketsFromPool,
  addContext,
  removeContext,
  addTicketsFromPool,
  refundOrder,
  setExternalId,
  deleteExternalId,
  bulkReject,
  bulkAssignMe,
  bulkAssignManager,
  editUserCustomer,
  loadRelated,
  loadCross,
  returnOrderOnSale,
  loadHistory,
  setTicketSearchStatus,
  saleTickets,
  loadViewHistory,
  setSite,
  setShowDeleteTicketsForm,
  setShowDeleteTicketsAndPoolForm,
  updateOrderField,
  updateOperatorStake,
  updateOutlay,
  addNotification,
  updateRoistat,
  updateOrderRefundRequest,
};
