import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD_DATA',
    'LOAD_DATA_SUCCESS',
    'LOAD_DATA_FAIL',

    'LOAD_MORE',

    'LOAD_DETAIL',
    'LOAD_DETAIL_SUCCESS',
    'LOAD_DETAIL_FAIL',

    'CLEAR_DETAIL_INVOICE_DATA',

    'ASSIGN_TO_ME',
    'ASSIGN_TO_ME_SUCCESS',
    'ASSIGN_TO_ME_FAIL',

    'ASSIGN_USER',
    'ASSIGN_USER_SUCCESS',
    'ASSIGN_USER_FAIL',

    'SET_AWAIT',
    'SET_AWAIT_SUCCESS',
    'SET_AWAIT_FAIL',

    'EDIT_USER_CUSTOMER',
    'EDIT_USER_CUSTOMER_SUCCESS',
    'EDIT_USER_CUSTOMER_FAIL',

    'EDIT_INVOICE_EVENT',
    'EDIT_INVOICE_EVENT_SUCCESS',
    'EDIT_INVOICE_EVENT_FAIL',

    'REJECT_INVOICE',
    'REJECT_INVOICE_SUCCESS',
    'REJECT_INVOICE_FAIL',

    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_FAIL',

    'CREATE_ORDER',
    'CREATE_ORDER_SUCCESS',
    'CREATE_ORDER_FAIL',
  ],
  {
    prefix: 'INVOICES.',
  },
);
