import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOGIN',
    'LOGIN_SUCCESS',
    'LOGIN_FAIL',

    'REGISTRATION',
    'REGISTRATION_SUCCESS',
    'REGISTRATION_FAIL',

    'LOGOUT',
    'LOGOUT_SUCCESS',
    'LOGOUT_FAIL',

    'CHANGE_PASSWORD',

    'RESET_PASSWORD',
    'RENEW_PASSWORD',

    'LOAD_PERMISSIONS',
    'LOAD_PERMISSIONS_SUCCESS',
    'LOAD_PERMISSIONS_FAIL',

    'LOAD_COMPANY_PERMISSIONS',
    'LOAD_COMPANY_PERMISSIONS_SUCCESS',
    'LOAD_COMPANY_PERMISSIONS_FAIL',

    'LOAD_ADMIN_PERMISSIONS',
    'LOAD_ADMIN_PERMISSIONS_SUCCESS',
    'LOAD_ADMIN_PERMISSIONS_FAIL',
  ],
  {
    prefix: 'AUTH.',
  },
);
