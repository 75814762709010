import camelCase from 'lodash/camelCase';

interface ConvertToCamelCaseProps {
  str: string;
  prefix?: string;
  postfix?: string;
}

const convertToCamelCase = (cfg: ConvertToCamelCaseProps) =>
  camelCase(`${cfg.prefix ? cfg.prefix : ''}${cfg.str}${cfg.postfix ? cfg.postfix : ''}`);

const POSTFIXS = {
  PAGE_FILTER_IS_OPEN: '_PAGE_FILTER_IS_OPEN',
  TABLE_SELECTED_ROWS: '_TABLE_SELECTED_ROWS',
  PAGE_SAVED_QUERIES: '_PAGE_SAVED_QUERIES',
  PAGE_EXPANDED_ROWS: '_PAGE_EXPANDED_ROWS',
  PAGE_ALL_ROWS_EXPANDED: '_PAGE_ALL_ROWS_EXPANDED',
  TABLE_DISABLED: '_TABLE_DISABLED',
};

export { POSTFIXS };

export default convertToCamelCase;
