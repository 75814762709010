import differenceBy from 'lodash/differenceBy';
import { actionTypes } from 'entities/pool/scheme';
import { actionTypes as announcementActionTypes } from 'entities/announcement';
import api from 'shared/services/api';
import announcementsSources from 'shared/sources/announcements';
import { selectSeat } from 'entities/announcement';
import SEAT_SELECTOR_TYPES from 'shared/constants/SEAT_SELECTOR_TYPES';
import { selectedTicketsIdsSelector } from '../../selectors/announcements';

const schemeMiddleware = (store) => (next) => (action) => {
  let prevSelectedAnnouncements = null;

  switch (action.type) {
    case actionTypes.SELECT_TICKET: {
      const { ticket } = action.payload;
      const { count } = ticket;
      const { poolDetail } = store.getState();
      const announcement = poolDetail.data[ticket.announcement_id];
      const type = count !== undefined ? SEAT_SELECTOR_TYPES.NUMBERED : SEAT_SELECTOR_TYPES.PLAIN;
      const data: { type: string; count?: number } = {
        type,
      };

      if (count !== undefined) {
        data.count = Number(count);
      }

      if (announcement) {
        next(
          selectSeat({
            ...data,
            announcement,
            ticket: {
              seat: ticket.seat,
              announcement_id: ticket.announcement_id,
              id: ticket.ticket_id,
            },
          }),
        );
      } else {
        api(announcementsSources.detail(ticket.announcement_id))
          .then(({ data: announcementData }) => {
            next(
              selectSeat({
                ...data,
                announcement: announcementData,
                ticket: {
                  seat: ticket.seat,
                  announcement_id: ticket.announcement_id,
                  id: ticket.ticket_id,
                },
              }),
            );
          })
          .catch((err) => new Error(err));
      }
      break;
    }
    case announcementActionTypes.DELETE_SELECTED_TICKET: {
      if (window.pwidget) {
        const state = store.getState();
        prevSelectedAnnouncements = selectedTicketsIdsSelector(state);
      }
      break;
    }
    default:
      break;
  }

  next(action);

  switch (action.type) {
    case announcementActionTypes.DELETE_SELECTED_TICKET: {
      if (prevSelectedAnnouncements) {
        const state = store.getState();
        const selectedAnnouncements = selectedTicketsIdsSelector(state);
        const ids = differenceBy(prevSelectedAnnouncements, selectedAnnouncements, 'id');

        window.pwidget.unselectTicketIds(ids);
      }
      break;
    }
    default:
      break;
  }
};

export default schemeMiddleware;
