import { actionTypes } from './actionTypes';
import { companySources } from 'entities/company';

const loadCompanyProviders = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: companySources.providers,
      params,
    },
  },
});

const loadMoreCompanyProviders = () => ({
  type: actionTypes.LOAD_MORE,
});

const addProvider = (data: any) => ({
  type: actionTypes.INVITE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.providers,
      data,
    },
  },
});

const editProvider = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: companySources.providersDetail(id),
      data,
    },
  },
});

const removeProvider = (id: number | string) => ({
  type: actionTypes.REMOVE,
  payload: {
    request: {
      method: 'DELETE',
      url: companySources.providersDetail(id),
      id,
    },
  },
});

export { loadCompanyProviders, loadMoreCompanyProviders, addProvider, removeProvider, editProvider };
