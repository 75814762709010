import React from 'react';
import Link from '../../Link';
import Styled from './styles';

export interface SidebarLinkProps {
  title: string;
  count?: number;
  href?: string;
  icon: string;
  onClick: () => void;
  root?: string;
  pathname: string;
  dataSelenium?: string;
  withNewLabel?: boolean;
  as?: string;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({
  title,
  count = 0,
  withNewLabel,
  href,
  as,
  icon,
  onClick,
  root,
  pathname,
  dataSelenium,
}) => {
  const isActive = pathname.indexOf(root || href) === 0;

  return (
    <Link href={href} as={as} onClick={onClick} data-selenium={dataSelenium}>
      <Styled.Item title={title} active={isActive}>
        <Styled.Icon active={isActive}>
          <img src={icon} alt="icon" aria-hidden="true" />
        </Styled.Icon>
        <Styled.Text active={isActive}>{title}</Styled.Text>
        {count !== 0 && <Styled.Count active={isActive}>{count}</Styled.Count>}
        {withNewLabel && (
          <Styled.Count isNew active={isActive}>
            New
          </Styled.Count>
        )}
      </Styled.Item>
    </Link>
  );
};

export default SidebarLink;
