// eslint-disable-next-line
function convertSeats(input) {
  const arr = input.sort((a, b) => a - b);
  let i: number;
  let idx = 0;
  let str = '';
  let val;

  const len = arr.length;
  while (idx < len) {
    i = 0;
    // eslint-disable-next-line
    str += val = arr[idx++];
    while (idx + i < len && Number(arr[idx + i]) === Number(val) + i + 1) {
      i += 1;
    }
    if (i > 1) {
      str += `-${arr[(idx += i) - 1]}`;
    }
    if (idx === len) {
      return str;
    }
    str += ', ';
  }
}

function seatsStringToArray(seatsString) {
  const ids = [];
  const seats = seatsString.split(',');

  seats.forEach((value) => {
    const onlyNumbers = /^\d+$/.test(value.trim());

    if (!onlyNumbers) {
      const [from, to]: [number, number] = value.split('-').map((i) => Number(i));

      const length = to - from + 1;
      ids.push(...Array.from({ length }, (v, k: number) => from + k));
    } else {
      ids.push(Number(value.trim()));
    }
  });

  return ids;
}

const getSeatsFromTickets = (tickets) =>
  tickets ? tickets.map((ticket) => ticket.seat).filter((seat) => !!seat) : [];

export { convertSeats, seatsStringToArray, getSeatsFromTickets };
