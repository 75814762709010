export default {
  DATE: 'YYYY-MM-DD',
  DATE_DOTS: 'DD.MM.YYYY',
  DAY: 'dd',
  TIME: 'HH:mm:ss',
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  DATE_TIME_PREVIEW: 'DD.MM.YYYY, HH:mm',
  DATE_PREVIEW: 'DD MMM YYYY',
  MONTH_REVIEW: 'DD MMM',
  TIME_PREVIEW: 'HH:mm',
  MONTH_TIME_PREVIEW: 'DD MMM HH:mm',
  DATE_TIME_FULL: 'DD MMM YYYY, HH:mm',
  ISO_8601: 'YYYY-MM-DDTHH:mm:ss.sssZ',
  FULL_DATE: 'D MMMM YYYY',
  ISO: 'YYYY-MM-DDTHH:mm:ss',
  DATE_TIME_DAYJS: 'YYYY-MM-DD HH:mm',
};
