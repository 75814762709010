import config from 'config/config';
import COOKIES from 'shared/constants/COOKIES';
import { getCookie } from 'shared/lib/cookie';
import api from 'shared/services/api';

const companyId = getCookie(COOKIES.COMPANY);

export const eventsSources = {
  root: `${config.COMMON_API_URL}/admin/v1/events`,
  rootv2: `${config.EVENT_API_URL}/api/event/v2`,
  search: `${config.EVENT_API_URL}/api/event/search`,
  detail: (id: number | string) => `${config.COMMON_API_URL}/admin/v1/events/${id}`,
  detailV2: (id: string | number) => `${config.COMMON_API_URL}/admin/v1/${companyId}/market/events/${id}`,
  delete: (id: number | string) => `${config.EVENT_API_URL}/api/event/${id}/delete`,
  bulkDate: `${config.EVENT_API_URL}/api/event/bulk_date`,
  countPool: (id: number | string) => `event/count/${id}/pool`,
  countAnnouncements: (id: number | string) => `/event/count/${id}/announcements`,
  metaPool: (id: number | string) => `event/meta/${id}/pool`,
  metaAnnouncements: (id: number | string) => `/event/meta/${id}/announcements`,
  getPermission: (id: number | string) => `${config.EVENT_API_URL}/api/event/${id}/get-permission`,
  mergeEvents: (id: number | string) => `${config.EVENT_API_URL}/api/event/${id}/merge`,

  async createQnA(id: number[], qnaforUpdate: number[]) {
    const promises = qnaforUpdate.map((qnaforUpdateId) => {
      return api.post(`${config.COMMON_API_URL}/admin/v1/${companyId}/market/events/${id}/qna`, {
        qna_id: qnaforUpdateId,
      });
    });

    return Promise.all(promises);
  },

  async deleteQnA(id: number[], qnaforDelete: number[]) {
    const promises = qnaforDelete.map((qnaforDeleteId) => {
      return api.delete(
        `${config.COMMON_API_URL}/admin/v1/${companyId}/market/events/${id}/qna/${qnaforDeleteId}`,
      );
    });

    return Promise.all(promises);
  },
};
