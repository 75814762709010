export const companySources = {
  root: 'company',
  emails: 'company/emails',
  phones: 'company/phones',
  landingPhones: 'company/landing-phone',
  members: 'company/members',
  providers: 'company/provider',
  payments: 'company/payment',
  invite: 'company/invite',
  list: 'company/list',
  role: 'company/role',
  userRole: 'company/user-role',
  managersAssign: 'company/managers-assign',
  courierList: 'company/courier-list',
  emailConfig: 'company/email-config',
  legalInfo: 'company/legal-info',
  atolConfig: 'company/atol-config',
  landingPhonesCity: 'company/landing-phone-city/',
  circleReport: 'company/circle-report',
  circlePayoutReport: 'company/circle-payout-report',
  providersDetail: (id: number | string) => `company/provider/${id}`,
  paymentsDetail: (id: number | string) => `company/payment/${id}`,
  membersDetail: (id: number | string) => `company/members/${id}`,
  emailsDetail: (id: number | string) => `company/emails/${id}`,
  phonesDetail: (id: number | string) => `company/phones/${id}`,
  landingPhonesDetail: (id: number | string) => `company/landing-phone/${id}`,
  defaultEmail: (id: number | string) => `company/emails/${id}/set-default`,
  defaultPhone: (id: number | string) => `company/phones/${id}/set-default`,
  roleDetail: (id: number | string) => `company/role/${id}`,
  setMemberIsActive: (id: number | string) => `company/members/active/${id}`,
  emailConfigDetail: (id: number | string) => `company/email-config/${id}`,
  legalInfoDetail: (id: number | string) => `company/legal-info/${id}`,
  atolConfigDetail: (id: number | string) => `company/atol-config/${id}`,
};
