import omit from 'lodash/omit';
import { actionTypes as announcementActionTypes } from 'entities/announcement';
import { actionTypes as orderActionTypes } from 'entities/order';
import STORAGE_TOKENS from 'shared/constants/STORAGE_TOKENS';

const poolMiddleware = (store) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case announcementActionTypes.SELECT_SEAT:
    case announcementActionTypes.DELETE_SELECTED_TICKET:
    case announcementActionTypes.UPDATE_SELECTED_TICKETS: {
      const poolId = window.location.pathname.split('/')[2];
      const { selectedAnnouncements } = store.getState();

      let storedSelectedAnnouncements: any = localStorage.getItem(STORAGE_TOKENS.SELECTED_ANNOUNCEMENTS);

      try {
        storedSelectedAnnouncements = JSON.parse(storedSelectedAnnouncements);
      } catch (e) {
        storedSelectedAnnouncements = {};
      }

      storedSelectedAnnouncements = {
        ...storedSelectedAnnouncements,
        [poolId]: selectedAnnouncements,
      };

      localStorage.setItem(
        STORAGE_TOKENS.SELECTED_ANNOUNCEMENTS,
        JSON.stringify(storedSelectedAnnouncements),
      );
      break;
    }
    case orderActionTypes.CREATE_SUCCESS:
    case orderActionTypes.ADD_TICKETS_FROM_POOL_SUCCESS: {
      const poolId = window.location.pathname.split('/')[2];
      const storedSelectedAnnouncements = JSON.parse(
        localStorage.getItem(STORAGE_TOKENS.SELECTED_ANNOUNCEMENTS),
      );

      const newStoredSelectedAnnouncements = omit(storedSelectedAnnouncements, [poolId]);

      localStorage.setItem(
        STORAGE_TOKENS.SELECTED_ANNOUNCEMENTS,
        JSON.stringify(newStoredSelectedAnnouncements),
      );
      break;
    }
    default:
  }
};

export default poolMiddleware;
