import { actionTypes } from './actionTypes';
import { searchSources } from './sources';

const loadOrders = (params = {}, loadMore = false) => ({
  type: actionTypes.LOAD_ORDERS,
  payload: {
    request: {
      url: searchSources.orders,
      params,
    },
    loadMore,
  },
});

const loadEvents = (params = {}, loadMore = false) => ({
  type: actionTypes.LOAD_EVENTS,
  payload: {
    request: {
      url: searchSources.events,
      params,
    },
    loadMore,
  },
});

const loadAnnouncementEvents = (params = {}, loadMore = false) => ({
  type: actionTypes.LOAD_ANNOUNCEMENT_EVENTS,
  payload: {
    request: {
      url: searchSources.announcementEvents,
      params,
    },
    loadMore,
  },
});

const loadPoolEvents = (params = {}, loadMore = false) => ({
  type: actionTypes.LOAD_POOL_EVENTS,
  payload: {
    request: {
      url: searchSources.poolEvents,
      params,
    },
    loadMore,
  },
});

export { loadOrders, loadEvents, loadAnnouncementEvents, loadPoolEvents };
