import { actionTypes } from './actionTypes';
import { orderSources } from 'entities/order';

const loadDelivery = (id: number | string) => ({
  type: actionTypes.LOAD,
  payload: {
    request: {
      url: orderSources.delivery(id),
    },
  },
});

const createDelivery = (id: number | string, data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.delivery(id),
      data,
    },
  },
});

const createDeliveryByEmail = (id: number | string, data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: orderSources.emailDelivery(id),
      data,
    },
  },
});

const deleteDelivery = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: orderSources.delivery(id),
    },
  },
});

const deleteSuccessDelivery = (id: number | string) => ({
  type: actionTypes.DELETE_SUCCESS_DELIVERY,
  payload: {
    request: {
      method: 'DELETE',
      url: orderSources.deliverySuccess(id),
    },
  },
});

const successDelivery = (id: number | string) => ({
  type: actionTypes.SUCCESS,
  payload: {
    request: {
      method: 'GET',
      url: orderSources.deliverySuccess(id),
    },
  },
});

export {
  loadDelivery,
  createDelivery,
  createDeliveryByEmail,
  deleteDelivery,
  deleteSuccessDelivery,
  successDelivery,
};
