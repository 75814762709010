export const membersCompaniesSources = {
  root: 'admin/company',
  detail: (id: number | string) => `admin/company/${id}`,
  permissions: 'admin/company-permissions',
  source: 'source/list',
  sourceDetailPrefix: (prefix: string) => `source/${prefix}`,
  sourcesDetail: (id: number | string) => `admin/company/${id}/sources`,
  zriteliAccess: (id: number | string) => `admin/company/${id}/zriteli_access`,
  permissionsDetail: (id: number | string) => `admin/company/${id}/permissions`,
  approve: (id: number | string) => `admin/company/${id}/approve`,
  deactivate: (id: number | string) => `admin/company/${id}/deactivate`,
  reactivate: (id: number | string) => `admin/company/${id}/reactivate`,
  allowedPermissions: (id: number | string) => `admin/company/${id}/allowed-permissions`,
};
