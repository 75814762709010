import { actionTypes } from './actionTypes';
import { companySources } from '../sources';
import { authSources } from 'entities/auth';

const loadCompanyMembers = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: companySources.members,
      params,
    },
  },
});

const loadMoreCompanyMembers = () => ({
  type: actionTypes.LOAD_MORE,
});

const loadCompanyMember = (id: number | string) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    request: {
      url: companySources.membersDetail(id),
    },
  },
});

const inviteNewMember = (data: any) => ({
  type: actionTypes.INVITE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.invite,
      data,
    },
  },
});

const removeMember = (id: number | string) => ({
  type: actionTypes.REMOVE,
  payload: {
    request: {
      method: 'DELETE',
      url: companySources.membersDetail(id),
      id,
    },
  },
});

const resetPassword = (email: string) => ({
  type: actionTypes.RESET_PASSWORD,
  payload: {
    request: {
      method: 'POST',
      url: authSources.resetPassword,
      data: { email },
    },
  },
});

const setMemberRole = (data: any) => ({
  type: actionTypes.SET_MEMBER_ROLE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.userRole,
      data,
    },
  },
});

const setMemberIsActive = (id: number | string, data: any) => ({
  type: actionTypes.SET_MEMBER_IS_ACTIVE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.setMemberIsActive(id),
      data,
      id,
    },
  },
});

export {
  loadCompanyMembers,
  loadMoreCompanyMembers,
  loadCompanyMember,
  inviteNewMember,
  removeMember,
  setMemberRole,
  setMemberIsActive,
  resetPassword,
};
