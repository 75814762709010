import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD_DATA',
    'LOAD_DATA_SUCCESS',
    'LOAD_DATA_FAIL',

    'LOAD_MORE',

    'LINK_PARSER_WITH_EVENT',
    'LINK_PARSER_WITH_EVENT_SUCCESS',
    'LINK_PARSER_WITH_EVENT_FAIL',

    'UNLINK_PARSER_WITH_EVENT',
    'UNLINK_PARSER_WITH_EVENT_SUCCESS',
    'UNLINK_PARSER_WITH_EVENT_FAIL',

    'SET_PARSER_STATUS',
    'SET_PARSER_STATUS_SUCCESS',
    'SET_PARSER_STATUS_FAIL',

    'ADD_EVENT',
    'ADD_EVENT_SUCCESS',
    'ADD_EVENT_FAIL',

    'BULK_IGNORE',
    'BULK_IGNORE_SUCCESS',
    'BULK_IGNORE_FAIL',
  ],
  {
    prefix: 'PARSERS_EVENTS.',
  },
);
