import get from 'lodash/get';
import some from 'lodash/some';

export function canView(permissions: { [key: string]: string }, permission: string | string[]) {
  const permissionArray = Array.isArray(permission) ? permission : [permission];

  return some(permissionArray, (currentPermission) => get(permissions, currentPermission));
}

export function createPermissionObject(permissions) {
  return permissions.reduce((acc, permission) => {
    acc[permission.alias] = !permission.disabled;

    return acc;
  }, {});
}
