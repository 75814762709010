import { actionTypes } from './actionTypes';

export const chartsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DETAIL_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: state.data.map((item) => (item.id === data.id ? data : item)),
      };
    }

    case actionTypes.DELETE_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.config.id),
        meta: { ...state.meta, count: state.meta.count - 1 },
      };
    }

    default:
      return undefined;
  }
};
