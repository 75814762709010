import { actionTypes } from './actionTypes';
import { widgetPreviewSources } from './sources';

const loadWidgets = (id: number | string) => ({
  type: actionTypes.LOAD,
  payload: {
    request: {
      url: widgetPreviewSources.list(id),
    },
  },
});

const createWidget = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: widgetPreviewSources.create,
      data,
    },
  },
});

export { loadWidgets, createWidget };
