import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD_DRAFTS',
    'LOAD_DRAFTS_SUCCESS',
    'LOAD_DRAFTS_FAIL',

    'CREATE_DRAFT',
    'CREATE_DRAFT_SUCCESS',
    'CREATE_DRAFT_FAIL',

    'EDIT_DRAFT',
    'EDIT_DRAFT_SUCCESS',
    'EDIT_DRAFT_FAIL',

    'DELETE_DRAFT',
    'DELETE_DRAFT_SUCCESS',
    'DELETE_DRAFT_FAIL',
  ],
  {
    prefix: 'DRAFTS.',
  },
);
