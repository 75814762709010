import { actionTypes } from './actionTypes';

export const invoicesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DETAIL_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: state.data.map((item) => (item.id === data.id ? data : item)),
      };
    }

    default:
      return undefined;
  }
};
