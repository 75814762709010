import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'OPEN',
    'CLOSE',
    'OPEN_ROUTER_MODAL',
    'CLOSE_ROUTER_MODAL',
    'ADD_PREVIOUS_MODAL',
    'REMOVE_PREVIOUS_MODAL',
    'CHANGE_STATE',
    'EDIT_DATA',
  ],
  {
    prefix: 'MODAL.',
  },
);
