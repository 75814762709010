import { actionTypes } from './actionTypes';

const INITIAL_STATE = {
  data: null,
};

export function orderContextReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.ADD_CONTEXT:
      return {
        ...state,
        data: action.payload.data,
      };
    case actionTypes.REMOVE_CONTEXT:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
}

export const selectOrderContext = (state) => state.orderContext.data;
