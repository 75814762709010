import styled, { css } from 'styled-components';

export default {
  Number: styled.div`
    display: inline-block;
    padding-right: 0.1em;
  `,

  Icon: styled.img<{ white?: boolean; currencyIcon?: boolean }>`
    max-height: ${({ currencyIcon }) => (currencyIcon ? '10px' : '12px')};
    margin: ${({ currencyIcon }) => currencyIcon && '0 3px 0 3px'};
    opacity: ${({ currencyIcon }) => currencyIcon && ' 0.6'};

    width: auto;

    ${({ white }) =>
      white &&
      css`
        filter: brightness(0) invert(1);
      `}
  `,
};
