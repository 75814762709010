import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD',
    'LOAD_SUCCESS',
    'LOAD_FAIL',

    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_FAIL',

    'DELETE',
    'DELETE_SUCCESS',
    'DELETE_FAIL',

    'DELETE_SUCCESS_DELIVERY',
    'DELETE_SUCCESS_DELIVERY_SUCCESS',
    'DELETE_SUCCESS_DELIVERY_FAIL',

    'SUCCESS',
    'SUCCESS_SUCCESS',
    'SUCCESS_FAIL',
  ],
  {
    prefix: 'DELIVERY.',
  },
);
