import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SidebarLink from './SidebarLink';
import api from 'shared/services/api';
import { invoicesSources } from 'entities/invoices';
import { SidebarLinkProps } from './SidebarLink/SidebarLink';

interface Props extends SidebarLinkProps {
  invoices: any[];
}

const SidebarInvoices: React.FC<Props> = (props) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const loadCount = () => {
      api
        .get(invoicesSources.count, { params: { status__in: 0 } })
        .then((response) => {
          setCount(response.data.data.count);
        })
        .catch((err) => new Error(err));
    };

    loadCount();
  }, [props.invoices]);

  return <SidebarLink {...props} count={count} />;
};

const mapDispatchToProps = (state) => ({
  invoices: state.invoices.data,
});

export default connect(mapDispatchToProps)(SidebarInvoices);
