import get from 'lodash/get';
import { actionTypes } from 'entities/order';
import { actionTypes as paymentActionTypes } from 'entities/payment';
import { ORDER_STATUSES } from 'shared/constants/ORDER';

const INITIAL_STATE = {
  data: {},
  id: null,
  isSales: null,
  isOpen: false,
  showDeleteTicketsForm: false,
  shouldDeteleFromPool: false,
  errorResponse: {},
  state: {
    isLoading: true,
    isFail: false,
  },
};

export const ordersDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.OPEN:
      return {
        ...state,
        id: action.payload.id,
        isSales: action.payload.isSales,
        isOpen: true,
      };

    case actionTypes.CLOSE:
      return INITIAL_STATE;

    case actionTypes.LOAD_ORDER:
      return {
        ...state,
        state: {
          isLoading: true,
          isFail: false,
        },
      };

    case actionTypes.LOAD_ORDER_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        state: {
          isLoading: false,
          isFail: false,
        },
      };

    case actionTypes.LOAD_ORDER_FAIL:
      return {
        ...state,
        data: {},
        errorResponse: action.error.response,
        state: {
          isLoading: false,
          isFail: true,
        },
      };

    case actionTypes.LOAD_SALE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data,
        },
      };

    case actionTypes.DELETE_EXTERNAL_ID:
      return {
        ...state,
        data: {
          ...state.data,
          external_id: null,
        },
      };

    case actionTypes.UPDATE_STATUS: {
      const { id, newStatus } = action.payload;

      if (id !== get(state, 'data.id')) return state;

      return {
        ...state,
        data: {
          ...state.data,
          status: newStatus,
          ...(newStatus === ORDER_STATUSES.FINISHED && { has_delivery: true, has_payment: true }),
        },
      };
    }

    case actionTypes.UPDATE_INVOICE: {
      const { id } = action.payload;

      if (id !== get(state, 'data.id')) return state;

      return {
        ...state,
        data: {
          ...state.data,
          send_invoice: true,
        },
      };
    }

    case actionTypes.UPDATE_MANAGER: {
      const { id, manager } = action.payload;

      if (id !== get(state, 'data.id')) return state;

      return {
        ...state,
        data: {
          ...state.data,
          manager,
        },
      };
    }

    case paymentActionTypes.CREATE_INVOICE_SUCCESS: {
      const { id } = action.meta.previousAction.payload;

      if (id !== get(state, 'data.id')) return state;

      return {
        ...state,
        data: {
          ...state.data,
          send_invoice: true,
        },
      };
    }

    case actionTypes.SHOW_DELETE_TICKETS_FORM:
      return {
        ...state,
        showDeleteTicketsForm: action.payload.value,
        shouldDeteleFromPool: false,
      };

    case actionTypes.SHOW_DELETE_TICKETS_AND_POOL_FORM:
      return {
        ...state,
        showDeleteTicketsForm: action.payload.value,
        shouldDeteleFromPool: action.payload.value,
      };

    case actionTypes.REFUND_REQUEST_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { refund_request: refundRequest } = action.meta.previousAction.payload.request.data;

      return {
        ...state,
        data: {
          ...state.data,
          refund_request: refundRequest,
        },
      };

    default:
      return state;
  }
};
