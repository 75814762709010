import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { useDispatch } from 'react-redux';
import { multiClientMiddleware } from 'redux-axios-middleware';
import authMiddleware from 'shared/middleware/auth';
import bookingMiddleware from 'shared/middleware/booking';
import callContextMiddleware from 'shared/middleware/callContext';
import i18nRequestsMiddleware from 'shared/middleware/i18nRequests';
import invoiceMiddleware from 'shared/middleware/invoice';
import modalMiddleware from 'shared/middleware/modal';
import orderMiddleware from 'shared/middleware/order';
import poolMiddleware from 'shared/middleware/pool';
import requestMiddleware from 'shared/middleware/request';
import requestTimestampMiddleware from 'shared/middleware/requestTimestamp';
import schemeMiddleware from 'shared/middleware/scheme';
import api, { ticketsApi } from 'shared/services/api';
import reducers from '../reducers';

const axiosMiddlewareConfig = {
  default: {
    client: api,
  },
  ticketsApi: {
    client: ticketsApi,
  },
};

const makeStore = () =>
  configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        i18nRequestsMiddleware,
        requestTimestampMiddleware,
        multiClientMiddleware(axiosMiddlewareConfig),
        requestMiddleware,
        authMiddleware,
        schemeMiddleware,
        bookingMiddleware,
        poolMiddleware,
        orderMiddleware,
        invoiceMiddleware,
        callContextMiddleware,
        modalMiddleware,
      ]),
  });

const wrapper = createWrapper(makeStore);

export type RootState = ReturnType<typeof makeStore>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();

export { wrapper };
