export const authSources = {
  login: 'auth/login',
  logout: 'auth/logout',
  registration: 'auth/registration',
  changePassword: 'auth/change-password',
  resetPassword: 'auth/reset-password',
  renew: 'auth/renew',
  userPermissions: 'auth/user-permissions',
  companyPermissions: 'auth/company-permissions',
  adminPermissions: 'auth/admin-permissions',
};
