import { orderSources } from 'entities/order';
import { actionTypes } from './actionTypes';
import omit from 'lodash/omit';

export const loadPurchases = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: orderSources.root,
      params: {
        ...omit(params, ['date_id', 'event_date_id_in']),
        ...(params.event_date_id_in && params.date_id && { event_date_id_in: params.date_id }),
        my_sales: true,
      },
    },
  },
});

export const loadPurchasesMore = () => ({
  type: actionTypes.LOAD_MORE,
});

export const addNewPurchase = (data: any) => ({
  type: actionTypes.ADD_NEW,
  payload: {
    data,
  },
});
