import { actionTypes } from './actionTypes';

const selectTicketFromScheme = (ticket: any) => ({
  type: actionTypes.SELECT_TICKET,
  payload: {
    ticket,
  },
});

const openScheme = () => ({
  type: actionTypes.OPEN,
});

const closeScheme = (closedByBookingModal = false) => ({
  type: actionTypes.CLOSE,
  payload: {
    closedByBookingModal,
  },
});

const openSchemeContext = () => ({
  type: actionTypes.OPEN_CONTEXT,
});

const closeSchemeContext = () => ({
  type: actionTypes.CLOSE_CONTEXT,
});

export { selectTicketFromScheme, openScheme, closeScheme, openSchemeContext, closeSchemeContext };
