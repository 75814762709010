import omit from 'lodash/omit';
import { actionTypes } from 'entities/order';
import { salesActionTypes } from 'entities/orders';
import { actionTypes as paymentActionTypes } from 'entities/payment';

export const salesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SALE_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [data.id]: {
            ...state.data[data.id],
            ...data,
            tickets: data.tickets ? data.tickets : [],
            external_id: data.external_id || null,
          },
        },
      };
    }

    case actionTypes.LOAD_ORDER_SUCCESS: {
      const { data } = action.payload;
      const oldData = data.external_id ? state.data[data.id] : omit(state.data[data.id], 'external_id');
      const tickets = data.tickets ? data.tickets : [];

      return {
        ...state,
        data: {
          ...state.data,
          [data.id]: {
            ...oldData,
            ...data,
            tickets,
          },
        },
      };
    }

    case actionTypes.UPDATE_FIELD: {
      const { id, name, value } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            [name]: value,
          },
        },
      };
    }

    case paymentActionTypes.CREATE_INVOICE_SUCCESS: {
      const { id } = action.meta.previousAction.payload;

      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            send_invoice: true,
          },
        },
      };
    }

    case salesActionTypes.ADD_NEW: {
      const { id } = action.payload.data;

      if (state.data[action.payload.data.id]) return state;

      return {
        ...state,
        data: {
          [id]: action.payload.data,
          ...state.data,
        },
        ids: [id, ...state.ids],
      };
    }

    default:
      return undefined;
  }
};
