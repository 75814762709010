import get from 'lodash/get';

const isAxiosRequest = (action) => action.payload && action.payload.request;

const requestMiddleware = () => {
  const tokensMap = {};

  return (next) => (action) => {
    if (!isAxiosRequest(action)) {
      return next(action);
    }
    const { payload, type } = action;

    if (!type.endsWith('_SUCCESS') && !type.endsWith('_FAIL')) {
      tokensMap[type] = payload.timestamp;

      return next(action);
    }

    const prevType = type.replace('_SUCCESS', '').replace('_FAIL', '');

    if (tokensMap[prevType] === get(action, 'meta.previousAction.payload.timestamp')) {
      tokensMap[prevType] = undefined;
      return next(action);
    }

    return null;
  };
};

export default requestMiddleware;
