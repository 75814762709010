import React from 'react';
import { connect } from 'react-redux';
import { canView } from 'shared/helpers/permissions';
import SidebarLink from '../Sidebar/SidebarLink';
import routes from 'shared/helpers/routes';
import permissions from 'config/permissions';
import { SidebarLinkProps } from '../Sidebar/SidebarLink/SidebarLink';

const companyLinks = [
  {
    route: routes.myCompany,
    permission: [permissions.companyUpdateInfo, permissions.companyContactInfo],
  },
  {
    route: routes.myCompanyMembers,
    permission: permissions.companyInviteUser,
  },
  {
    route: routes.myCompanyRoles,
    permission: permissions.companyUserAdmin,
  },
  {
    route: routes.myCompanyProviders,
    permission: permissions.companyProvider,
  },
  {
    route: routes.myCompanyLegalInfo,
    permission: permissions.companyOptions,
  },
  {
    route: routes.myCompanyPayments,
    permission: permissions.companyPayments,
  },
  {
    route: routes.myCompanyEmailConfig,
    permission: permissions.companyOptions,
  },
  {
    route: routes.myCompanyAtolConfig,
    permission: permissions.companyOptions,
  },
  {
    route: routes.myCompanyPhones,
    permission: permissions.companyOptions,
  },
];

interface LinkProps extends SidebarLinkProps {
  permissions?: any;
}

const CompanySidebarLink: React.FC<LinkProps> = ({ permissions: userPermissions, ...rest }) => {
  const links = companyLinks.filter((item) => canView(userPermissions, item.permission));

  return <SidebarLink {...rest} href={links[0].route} />;
};

const mapStateToProps = (state) => ({
  permissions: state.permissions,
});
export default connect(mapStateToProps)(CompanySidebarLink);
