/* eslint-disable */

export default {
  reference: 'admin_reference',

  editor: 'admin_editor',

  companySuperManager: 'company_super_manager',

  accessToInvoices: 'access_to_invoices', // Доступ к заявкам

  reportDownload: 'company_report_download', // Скачивание отчетов
  reportPurchase: 'company_report_purchase', // Доступ к отчету по закупкам
  reportRefund: 'company_report_refund', // Доступ к отчету по возвратам
  reportSales: 'company_report_sales', // Доступ к отчету по продажам
  reportOrder: 'company_report_order', // Доступ к отчету по заказам
  reportDelivery: 'company_report_delivery', // Доступ к отчету по доставкам
  reportExpired: 'company_report_expired', // Доступ к отчету по гари
  reportEvent: 'company_report_event', // Доступ к отчету по мероприятию
  reportReject: 'company_report_reject', // Доступ к отчету по отменам
  reportPrincipal: 'company_report_principal', // Доступ к отчету по организаторам

  widget: 'company_widget', // Просмотр виджетов
  widgetEdit: 'company_widget_edit', // Редактирование виджетов

  pool: 'company_pool', // Доступ к билетному пулу

  event: 'admin_event',

  companyManager: 'admin_company_manager',
  companyUser: 'admin_user_manager',

  crawler: 'admin_crawler',

  accessToClients: 'access_to_clients', // Доступ к разделу клиентов

  landings: 'company_seo', // Доступ к разделу лендинги

  companyUpdateInfo: 'company_update_info', // Обновление основной информации компании
  companyInviteUser: 'company_invite_user', // Добавление/удаление пользователей компании
  companyContactInfo: 'company_contact_info', // Добавление/редактирование контактной информации (телефон/почта)
  companyProvider: 'company_provider', // Добавление/редактирование поставщиков
  companyPayments: 'company_payments', // Добавление/редактирование методов оплаты
  companyOptions: 'my_company_options', // обновление настроек компании для лендингов
  companyUserAdmin: 'company_user_admin', // Создание ролей

  companyEditAnnouncement: 'company_edit_announcement', // Доступ к редактированию объявлени
  companyViewAnnouncement: 'company_view_announcement', // Доступ к разделу "Мои объявления
  companyMassActionAnnouncement: 'company_mass_action_announcement', // Доступ к массовым действия над объявлениям
  companyHistoryAnnouncement: 'company_history_announcement', // Доступ к истории изменения объявлени

  companyAccessToOrder: 'company_access_to_order', // "Доступ к продажам",
  companyAssingOrderToMe: 'company_assing_order_to_me', // "Может брать свободный заказ на себя"
  companyAssingOrder: 'company_assing_order', // "Может назначать заказ"
  companyCreateOrder: 'company_create_order', // "Может создавать заказ" добавлять билеты

  companyManageTicketsInOrder: 'company_manage_tickets_in_order', // "Может управлять составом заказа"
  companyOrderCancelDelivery: 'company_order_cancel_delivery', // "Может отменить доставку"
  companyOrderFinishDelivery: 'company_order_finish_delivery', // Может завершить доставку
  companyOrderDelivery: 'company_order_delivery', // Может выставить доставку

  companyOrderPayment: 'company_order_payment', // Может выставить тип оплаты
  companyOrderGetMoney: 'company_order_get_money', // Может завершить оплату
  companyOrderCancelMoney: 'company_order_cancel_money', // Может отменить оплату
  companyFinishOrder: 'company_finish_order', // Может завершить заказ
  companyRejectOrder: 'company_reject_order', // Может отменить заказ
  companyOrderSetDiscount: 'company_order_set_discount', // Может выставлять скидку в заказе
  companyOrderLandings: 'company_order_landings', // Может установить внешний номер заказа
  companyRefundOrder: 'company_refund_order', // Может оформить возврат
  companyUpdateCustomer: 'company_update_customer', // Может обновить контактную информацию клиента
  companyOrderMassAction: 'company_order_mass_action', // Имеет доступ к массовым действиям над заказами
  companyHistoryOrder: 'company_history_order', // Имеет доступ к истории заказа
  companyOrderInSaleAnnouncement: 'company_order_in_sale_announcement', // Возможность выставлять в наличии из заказа
  companyOrderViewHistoryView: 'company_order_view_history_view', // Возможность просмотра истории просмотра заказа
  companyManagePreOrderTicketsInOrder: 'company_manage_pre_order_tickets_in_order', // Возможность удалять билеты из заказа и пула

  companyAccessToPreOrder: 'company_access_to_pre_order', // Имеет доступ к предзаказам

  companyReportMarketplace: 'company_report_marketplace', // Может просматривать отчеты Portalbilet

  userRoistatField: 'user_roistat_field', // Может редактировать поле roistat
};
