import { IResources } from '../../../interfaces/resources';
import { i18n } from '../../i18n';

const INITIAL_STATE: IResources<any> = {
  data: [],
  meta: {},
  state: {
    isLoading: true,
    isFail: false,
    isLoadingMore: false,
  },
};

export function createReferenceTabReducer(actionType = '', params: any = {}) {
  const { withoutPagination } = params;

  return function referenceTab(state = INITIAL_STATE, action) {
    switch (action.type) {
      case `${actionType}.LOAD`:
        return {
          ...state,
          data: [],
          meta: {},
          state: {
            isLoading: true,
            isFail: false,
          },
        };
      case `${actionType}.LOAD_SUCCESS`: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { resutls, ...meta } = action.payload.data.data;

        return {
          ...state,
          meta,
          data: action.payload.data.data.results,
          state: {
            isLoading: false,
            isFail: false,
          },
        };
      }
      case `${actionType}.LOAD_FAIL`:
        return {
          ...state,
          data: [],
          meta: {},
          state: {
            isLoading: false,
            isFail: false,
          },
        };

      case `${actionType}.CREATE`: {
        const newData = action.payload.data[i18n.language];
        const data =
          state.data.length === state.meta.limit && !withoutPagination
            ? state.data
            : [
                ...state.data,
                {
                  ...newData,
                  id: newData.id || newData.item_id,
                },
              ];

        const meta =
          state.data.length === state.meta.limit
            ? {
                ...state.meta,
                last_page: state.meta.last_page + 1,
              }
            : state.meta;

        return {
          ...state,
          data,
          meta: { ...meta, count: meta.count + 1 },
        };
      }

      case `${actionType}.DELETE_SUCCESS`: {
        return {
          ...state,
          data: state.data.filter((item) => item.id !== action.payload.config.id),
          meta: { ...state.meta, count: state.meta.count - 1 },
        };
      }

      case `${actionType}.UPDATE`: {
        const newData = action.payload.request
          ? action.payload.request.data
          : action.payload.data[i18n.language];

        if (!newData) return state;
        return {
          ...state,
          data: state.data.map((item) => {
            if (item.id === newData.id || item.id === newData.item_id) {
              return {
                ...item,
                ...newData,
                id: item.id,
              };
            }

            return item;
          }),
        };
      }

      case `${actionType}.SET_STATE`:
        return {
          ...state,
          state: action.payload.state,
        };

      case `${actionType}.SET_DATA`: {
        const { data, meta } = action.payload;

        return {
          ...state,
          data,
          meta,
          state: {
            isLoading: false,
            isFail: false,
          },
        };
      }
      default:
        return state;
    }
  };
}
