import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'TOGGLE_BAR_STATE',
    'SET_BAR_STATE',
    'SELECT_ROW',
    'SELECT_ALL_ROWS',
    'UNSELECT_ALL_ROWS',
    'UPDATE_QUERIES',
    'SET_QUERIES',
    'SET_ROWS_ERRORS',
    'SELECT_MANY_ROWS',
    'EXPAND_ALL_ROWS',
    'SET_EXPANDED_ALL_ROWS',
    'TOGGLE_SHOW_ONLY_ERRORS',
    'SET_DISABLED',
  ],
  {
    prefix: 'TABLES.',
  },
);
