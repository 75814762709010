import Link from 'components/Link';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccount } from 'entities/account';
import { IUser } from 'entities/account/types';
import routes from 'shared/helpers/routes';
import Styled from './styles';

function getUserName(data: IUser) {
  const { first_name: firstName, last_name: lastName, username } = data;

  let userName = username?.slice(0, 2).toUpperCase();

  if (firstName && lastName) {
    userName = `${firstName[0]}${lastName[0]}`.toUpperCase();
  }

  return userName;
}

export const UserProfileIcon = () => {
  const user = useSelector(selectAccount);

  return (
    <Link href={routes.profile} data-selenium="user-profile">
      <Styled.User>{getUserName(user)}</Styled.User>
    </Link>
  );
};
