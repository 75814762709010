import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD',
    'LOAD_SUCCESS',
    'LOAD_FAIL',

    'LOAD_PROVIDER',
    'LOAD_PROVIDER_SUCCESS',
    'LOAD_PROVIDER_FAIL',

    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_FAIL',

    'DELETE',
    'DELETE_SUCCESS',
    'DELETE_FAIL',

    'DELETE_SUCCESS_PAYMENT',
    'DELETE_SUCCESS_PAYMENT_SUCCESS',
    'DELETE_SUCCESS_PAYMENT_FAIL',

    'SUCCESS',
    'SUCCESS_SUCCESS',
    'SUCCESS_FAIL',

    'SUCCESS_TIME',
    'SUCCESS_TIME_SUCCESS',
    'SUCCESS_TIME_FAIL',

    'CREATE_INVOICE',
    'CREATE_INVOICE_SUCCESS',
    'CREATE_INVOICE_FAIL',

    'SEND_INVOICE',
    'SEND_INVOICE_SUCCESS',
    'SEND_INVOICE_FAIL',

    'CLEAR_DATA',
  ],
  {
    prefix: 'PAYMENT.',
  },
);
