import figmaColorsTokens from '../../../design-tokens/colors.json';

interface IToken {
  $type: string;
  $value: string | number;
}

type TParsedData = IToken | { [key: string]: Record<string, IToken> | IToken };

export const getStyleTokens = (obj: Record<string, TParsedData>) => {
  const flatData: Record<string, string> = {};

  const getFlatData = (data: Record<string, TParsedData> | TParsedData, prefix: string = '') => {
    for (const key in data) {
      // @ts-expect-error @typescript-eslint/ban-ts-comment
      const value = data[key];

      if (typeof value === 'object' && !Array.isArray(value)) {
        if (value.$type === 'color' && value.$value) {
          flatData[`${prefix}${key}`] = value.$value;
        } else if (value.$type === 'number' && value.$value) {
          flatData[`${prefix}${key}`] = `${value.$value}px`;
        } else {
          getFlatData(value, `${prefix}${key}.`);
        }
      }
    }
  };

  getFlatData(obj);

  const resolvedData: Record<string, string> = {};
  for (const [key, value] of Object.entries(flatData)) {
    const keyWords = key.split('.');
    const parsedKey = keyWords[keyWords.length - 1];

    if (value.startsWith('{') && value.endsWith('}')) {
      const reference = value.slice(1, -1);
      resolvedData[Number.isNaN(Number(parsedKey)) ? parsedKey : key] = flatData[reference] || value;
    } else {
      resolvedData[Number.isNaN(Number(parsedKey)) ? parsedKey : key] = value;
    }
  }

  return resolvedData;
};

export const GLOBAL_COLORS = getStyleTokens(figmaColorsTokens);

export default {
  GRAYE1: '#E1E1E1',
  GRAYF5: '#F5F5F5',
  GRAY5C: '#5c5c5c',
  GRAY_MERCURY: '#E3E1E0',
  BLUE: '#2F80ED',
  GREEN: '#2DC472',
  SUCCESS: '#27AE60',
  YELLOW: '#FFDA00',
  RED: '#EB5757',
  PINK: '#fef2f2',
};
