import { actionTypes } from './actionTypes';
import { companySources } from '../sources';

const loadCompanyPayments = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: companySources.payments,
      params,
    },
  },
});

const loadMoreCompanyPayments = () => ({
  type: actionTypes.LOAD_MORE,
});

const addPayment = (data: any) => ({
  type: actionTypes.INVITE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.payments,
      data,
    },
  },
});

const editPayment = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: companySources.paymentsDetail(id),
      data,
    },
  },
});

const removePayment = (id: number | string) => ({
  type: actionTypes.REMOVE,
  payload: {
    request: {
      method: 'DELETE',
      url: companySources.paymentsDetail(id),
      id,
    },
  },
});

export { loadCompanyPayments, loadMoreCompanyPayments, addPayment, removePayment, editPayment };
