import Router from 'next/router';
import { actionTypes } from 'entities/modal';
import { updateQuery } from 'shared/helpers/routes';

const modalMiddleware = () => (next) => (action) => {
  next(action);

  if (action.type === actionTypes.OPEN_ROUTER_MODAL) {
    const { modalId, modalType } = Router.router.query;
    const {
      modalType: actionModalType,
      data: { id },
      query = {},
    } = action.payload;

    if (modalType !== actionModalType || modalId !== id) {
      updateQuery({ modalId: id, modalType: actionModalType, ...query });
    }
  }
};

export default modalMiddleware;
