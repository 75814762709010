export default {
  ADS: '/static/Sidebar/Ads.svg',
  BUY: '/static/Sidebar/Buy.svg',
  CALLS: '/static/Sidebar/Calls.svg',
  COMPANY: '/static/Sidebar/company.svg',
  EVENTS: '/static/Sidebar/Events.svg',
  REFERENCE: '/static/Sidebar/reference.svg',
  HOME: '/static/Sidebar/Home.svg',
  PARSING: '/static/Sidebar/Parsing.svg',
  REPORTS: '/static/Sidebar/Reports.svg',
  SALE_OFFICES: '/static/Sidebar/Sale_offices.svg',
  SELL: '/static/Sidebar/Sell.svg',
  TICKETS_POOL: '/static/Sidebar/Tickets_pool.svg',
  TRANSFER_TICKETS: '/static/Sidebar/Transfer_tickets.svg',
  WIDGET: '/static/Sidebar/Widget.svg',
  USERS: '/static/Sidebar/Users.svg',
  CLIENTS: '/static/Sidebar/Clients.svg',
  LANDINGS: '/static/Sidebar/Landings.svg',
  WIDGETS: '/static/Sidebar/Widget.svg',
  PREORDERS: '/static/Sidebar/Preorders.svg',
  BUNDLES: '/static/Sidebar/Podborki.svg',
};
