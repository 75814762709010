const INITIAL_STATE = {
  data: [],
  meta: {},
  shouldMerge: false,
  state: {
    isLoading: true,
    isLoadingMore: false,
    isFail: false,
  },
};

interface Props {
  actionType: string;
  reducerFunction: (state: any, action: string) => any;
}

export const createTableReducer =
  (actionType: Props['actionType'], reducerFunction?: Props['reducerFunction']) =>
  (state = INITIAL_STATE, action) => {
    if (reducerFunction) {
      const newState = reducerFunction(state, action);

      if (newState) {
        return newState;
      }
    }

    switch (action.type) {
      case `${actionType}.LOAD_DATA`: {
        const { shouldMerge } = state;
        return {
          ...state,
          data: shouldMerge ? state.data : [],
          meta: shouldMerge ? state.meta : {},
          state: {
            isLoading: !shouldMerge,
            isLoadingMore: shouldMerge,
            isFail: false,
          },
        };
      }
      case `${actionType}.LOAD_DATA_SUCCESS`: {
        const payloadData = action.payload.data.data || action.payload.data;
        const { results: data, ...meta } = payloadData;
        const { shouldMerge } = state;

        return {
          ...state,
          meta,
          data: shouldMerge ? [...state.data, ...data] : data,
          shouldMerge: false,
          state: {
            isLoading: false,
            isLoadingMore: false,
            isFail: false,
          },
        };
      }
      case `${actionType}.LOAD_DATA_FAIL`:
        return {
          ...state,
          data: [],
          meta: {},
          shouldMerge: false,
          state: {
            isLoading: false,
            isLoadingMore: false,
            isFail: true,
          },
        };
      case `${actionType}.LOAD_MORE`: {
        return {
          ...state,
          shouldMerge: true,
          isLoadingMore: true,
        };
      }

      default:
        return state;
    }
  };
