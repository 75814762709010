import { actionTypes } from './actionTypes';
import { membersCompaniesSources } from './sources';

const loadMembersCompanies = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: membersCompaniesSources.root,
      params,
    },
  },
});

const loadMoreMembersCompanies = (params = {}) => ({
  type: actionTypes.LOAD_MORE,
  payload: {
    request: {
      url: membersCompaniesSources.root,
      params,
    },
  },
});

const loadMemberCompany = (id: number | string) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    request: {
      url: membersCompaniesSources.detail(id),
    },
  },
});

const updateMemberPermissions = (data: any) => ({
  type: actionTypes.UPDATE,
  payload: {
    request: {
      method: 'POST',
      url: membersCompaniesSources.permissionsDetail(data.id),
      data: {
        permissions: data.permissions,
      },
    },
  },
});

const loadMemberSources = () => ({
  type: actionTypes.LOAD_SOURCES,
  payload: {
    request: {
      url: membersCompaniesSources.source,
      params: { limit: 99999 },
    },
  },
});

const updateMemberSources = (data: any) => ({
  type: actionTypes.UPDATE_SOURCES,
  payload: {
    request: {
      method: 'POST',
      url: membersCompaniesSources.sourcesDetail(data.id),
      data: {
        sources: data.permissions,
      },
    },
  },
});

const loadPermissions = () => ({
  type: actionTypes.LOAD_PERMISSIONS,
  payload: {
    request: {
      url: membersCompaniesSources.permissions,
      params: { limit: 99999 },
    },
  },
});

const setViewersAccess = (id: number | string, data: any) => ({
  type: actionTypes.SET_VIEWERS_ACCESS,
  payload: {
    request: {
      method: 'POST',
      url: membersCompaniesSources.zriteliAccess(id),
      data,
      id,
    },
  },
});

const approveCompany = (id: number | string) => ({
  type: actionTypes.APPROVE,
  payload: {
    request: {
      method: 'POST',
      url: membersCompaniesSources.approve(id),
      data: {},
    },
    id,
  },
});

const deactivateCompany = (id: number | string) => ({
  type: actionTypes.DEACTIVATE,
  payload: {
    request: {
      method: 'DELETE',
      url: membersCompaniesSources.deactivate(id),
    },
    id,
  },
});

const reactivateCompany = (id: number | string) => ({
  type: actionTypes.REACTIVATE,
  payload: {
    request: {
      method: 'PATCH',
      url: membersCompaniesSources.reactivate(id),
      data: {},
    },
    id,
  },
});

export {
  loadPermissions,
  setViewersAccess,
  loadMemberSources,
  loadMemberCompany,
  updateMemberSources,
  updateMemberPermissions,
  loadMembersCompanies,
  loadMoreMembersCompanies,
  approveCompany,
  deactivateCompany,
  reactivateCompany,
};
