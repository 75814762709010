const INITIAL_STATE = {
  emails: [],
  phones: [],
  emailsState: {
    isLoading: true,
    isFail: false,
  },
  phonesState: {
    isLoading: true,
    isFail: false,
  },
};

export function createContactsReducer(actionType = '') {
  return function contacts(state = INITIAL_STATE, action) {
    switch (action.type) {
      case `${actionType}.LOAD_EMAILS`:
        return {
          ...state,
          emailsState: {
            isLoading: true,
            isFail: false,
          },
        };
      case `${actionType}.LOAD_EMAILS_SUCCESS`:
        return {
          ...state,
          emails: action.payload.data.data.results,
          emailsState: {
            isLoading: false,
            isFail: false,
          },
        };
      case `${actionType}.LOAD_EMAILS_FAIL`:
        return {
          ...state,
          emailsState: {
            isLoading: false,
            isFail: true,
          },
        };

      case `${actionType}.LOAD_PHONES`:
        return {
          ...state,
          phonesState: {
            isLoading: true,
            isFail: false,
          },
        };
      case `${actionType}.LOAD_PHONES_SUCCESS`:
        return {
          ...state,
          phones: action.payload.data.data.results,
          phonesState: {
            isLoading: false,
            isFail: false,
          },
        };
      case `${actionType}.LOAD_PHONES_FAIL`:
        return {
          ...state,
          phonesState: {
            isLoading: false,
            isFail: true,
          },
        };

      case `${actionType}.CREATE_EMAIL_SUCCESS`:
        return {
          ...state,
          emails: [...state.emails, action.payload.data],
        };

      case `${actionType}.CREATE_PHONE_SUCCESS`:
        return {
          ...state,
          phones: [...state.phones, action.payload.data],
        };

      case `${actionType}.UPDATE_PHONE_SUCCESS`:
        return {
          ...state,
          phones: state.phones.map((item) =>
            item.id === action.payload.data.id ? action.payload.data : item,
          ),
        };

      case `${actionType}.UPDATE_EMAIL_SUCCESS`:
        return {
          ...state,
          emails: state.emails.map((item) =>
            item.id === action.payload.data.id ? action.payload.data : item,
          ),
        };

      case `${actionType}.DELETE_PHONE_SUCCESS`: {
        const { id } = action.meta.previousAction.payload;

        return {
          ...state,
          phones: state.phones.filter((item) => item.id !== id),
        };
      }

      case `${actionType}.DELETE_EMAIL_SUCCESS`: {
        const { id } = action.meta.previousAction.payload;

        return {
          ...state,
          emails: state.emails.filter((item) => item.id !== id),
        };
      }

      case `${actionType}.SET_DEFAULT_EMAIL_SUCCESS`: {
        const { id } = action.meta.previousAction.payload;

        return {
          ...state,
          emails: state.emails.map((item) => ({
            ...item,
            default: item.id === id,
          })),
        };
      }
      case `${actionType}.SET_DEFAULT_PHONE_SUCCESS`: {
        const { id } = action.meta.previousAction.payload;

        return {
          ...state,
          phones: state.phones.map((item) => ({
            ...item,
            default: item.id === id,
          })),
        };
      }
      default:
        return state;
    }
  };
}
