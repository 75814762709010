import config from 'config/config';

const url = config.CRAWLER_API_URL || '';

export const parsersEventsSources = {
  root: `${url}crawler/event`,
  detail: (id: number | string) => `${url}crawler/event/${id}`,
  link: (id: number | string) => `${url}crawler/event/${id}/set-event`,
  unlink: (id: number | string) => `${url}crawler/event/${id}/unlink-event`,
  status: (id: number | string) => `${url}crawler/event/${id}/set-status`,
  eventParsers: (id: number | string) => `${url}crawler/list/${id}`,
  bulkIgnore: `${url}crawler/event/bulk-ignore`,
};
