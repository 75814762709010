import { actionTypes } from './actionTypes';
import { companySources } from 'entities/company';

const loadRoles = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: companySources.role,
      params,
    },
  },
});

const loadMoreRoles = () => ({
  type: actionTypes.LOAD_MORE,
});

const loadRole = (id: number | string) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    request: {
      url: companySources.roleDetail(id),
    },
  },
});

const createRole = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.role,
      data,
    },
  },
});

const updateRole = (data: any) => ({
  type: actionTypes.UPDATE,
  payload: {
    request: {
      method: 'PATCH',
      url: companySources.roleDetail(data.id),
      data,
    },
  },
});

const deleteRole = (data: any) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: companySources.roleDetail(data.id),
      id: data.id,
    },
  },
});

export { loadRoles, loadMoreRoles, loadRole, createRole, updateRole, deleteRole };
