import EventEmmiter from 'eventemitter3';
import { getCookie } from 'shared/lib/cookie';
import COOKIES from 'shared/constants/COOKIES';
import config from 'config/config';

const RECOUNNECT_TIMEOUT = 5 * 1000;

class WebsocketService extends EventEmmiter {
  callbacks: any[];

  token: any;

  ws: WebSocket;

  pingInterval: any;

  constructor() {
    super();

    this.callbacks = [];

    if (typeof WebSocket !== 'undefined') {
      const token = getCookie(COOKIES.WS_TOKEN);

      if (token) {
        this.token = token;
        this.open(this.token);
      }
    }
  }

  open = (token = this.token, callbacks = []) => {
    this.ws = new WebSocket(`${config.WS_API_URL}ws/init/${token}`);
    this.ws.addEventListener('message', this.handleReceiveMessage);
    this.ws.onclose = this.handleClose;
    this.ws.onerror = this.handleError;

    callbacks.forEach((callback) => this.on('receiveMessage', callback));

    this.pingInterval = setInterval(() => {
      if (this.ws.readyState === 1) {
        this.ws.send('ping');
      }
    }, 25 * 1000);
  };

  handleReceiveMessage = (event) => {
    if (event.data === 'pong') return;

    this.emit('receiveMessage', event);
  };

  reconnect = () => {
    this.removeAllListeners();
    clearInterval(this.pingInterval);

    setTimeout(() => {
      this.open(this.token, this.callbacks);
    }, RECOUNNECT_TIMEOUT);
  };

  addEvent = (callback) => {
    this.on('receiveMessage', callback);
    this.callbacks.push(callback);
  };

  removeEvent = (callback) => {
    this.removeListener('receiveMessage', callback);
    this.callbacks = this.callbacks.filter((i) => i !== callback);
  };

  close = () => {
    this.ws.close();
    this.removeAllListeners();
    clearInterval(this.pingInterval);
  };

  handleClose = (event) => {
    if (event.code !== 1000) {
      this.reconnect();
    }
  };

  handleError = (event) => {
    if (event.code === 'ECONNREFUSED') {
      this.reconnect();
    }
  };
}

export default new WebsocketService();
