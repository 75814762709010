import { actionTypes } from './actionTypes';
import { actionTypes as widgetActionTypes } from 'entities/widget-preview';

const INITIAL_STATE = {
  data: [],
  count: 0,
  state: {
    isLoading: true,
    isFail: false,
  },
  createState: {
    isLoading: false,
  },
};

export const hallLayoutsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        data: [],
        count: 0,
        state: {
          isLoading: true,
          isFail: false,
        },
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data.results,
        count: action.payload.data.data.count,
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    case actionTypes.LOAD_FAIL:
      return {
        ...state,
        data: [],
        count: 0,
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    case actionTypes.CREATE:
      return {
        ...state,
        createState: {
          isLoading: true,
        },
      };
    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload.data],
        createState: {
          isLoading: false,
        },
      };
    case actionTypes.CREATE_FAIL:
      return {
        ...state,
        createState: {
          isLoading: false,
        },
      };
    case widgetActionTypes.CREATE_SUCCESS: {
      const { hall_layout: hallLayoutId, id } = action.payload.data;

      return {
        ...state,
        data: state.data.map((item) =>
          item.id === hallLayoutId
            ? {
                ...item,
                has_widget: id,
              }
            : item,
        ),
      };
    }
    default:
      return state;
  }
};
