export default {
  COMMON: 'common',
  SIDEBAR: 'sidebar',
  HOME: 'home',
  REFERENCES: 'references',
  FORMS: 'forms',
  LANDINGS: 'landings',
  EVENTS: 'events',
  NOTIFICATIONS: 'notifications',
  CLIENTS: 'clients',
  WIDGETS: 'widgets',
  BUNDLES: 'bundles',
  MARKET: 'market',
};
