import { HYDRATE } from 'next-redux-wrapper';
import { actionTypes } from './actionTypes';

const INITIAL_STATE = {
  sidebarIsOpen: false,
  userAgent: null,
};

export function layoutReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_SIDEBAR_STATE:
      return {
        ...state,
        sidebarIsOpen: !state.sidebarIsOpen,
      };

    case actionTypes.SET_SIDEBAR_STATE:
      return {
        ...state,
        sidebarIsOpen: action.payload.data,
      };

    case actionTypes.SET_USER_AGENT:
      return {
        ...state,
        userAgent: action.payload.data,
      };

    case HYDRATE:
      return {
        ...state,
        ...action.payload.layout,
      };

    default:
      return state;
  }
}
