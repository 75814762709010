import { actionTypes } from '../actionTypes';

const INITIAL_STATE = {
  data: {},
  state: {
    isLoading: true,
    isFail: false,
  },
  errorResponse: null,
};

export const invoicesDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DETAIL:
      return {
        ...state,
        state: {
          isLoading: true,
          isFail: false,
        },
      };

    case actionTypes.LOAD_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        state: {
          isLoading: false,
          isFail: false,
        },
      };

    case actionTypes.LOAD_DETAIL_FAIL:
      return {
        ...state,
        data: {},
        errorResponse: action.error.response,
        state: {
          isLoading: false,
          isFail: true,
        },
      };

    case actionTypes.CLEAR_DETAIL_INVOICE_DATA:
      return INITIAL_STATE;

    default:
      return state;
  }
};
