import { actionTypes } from './actionTypes';
import { actionTypes as orderActionTypes } from 'entities/order';

const INITIAL_STATE = {
  data: {},
  shouldOpen: false,
  shouldClear: false,
};

export function callContextReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.CREATE:
      return {
        ...state,
        data: action.payload.data,
      };

    case actionTypes.REMOVE:
      return {
        ...state,
        data: {},
        shouldClear: false,
      };

    case actionTypes.SHOULD_OPEN:
      return {
        ...state,
        shouldOpen: true,
      };

    case actionTypes.SHOULD_CLOSE:
      return {
        ...state,
        shouldOpen: false,
      };

    case orderActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        shouldClear: true,
      };

    default:
      return state;
  }
}
