import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'CLOSE',

    'ADD_COMMENT',
    'ADD_COMMENT_SUCCESS',
    'ADD_COMMENT_FAIL',

    'ADD_COMMENT_ATTACHMENT',
    'ADD_COMMENT_ATTACHMENT_FAIL',

    'LOAD_COMMENTS',
    'LOAD_COMMENTS_SUCCESS',
    'LOAD_COMMENTS_FAIL',

    'LOAD_COMMENT',
    'LOAD_NEW_STATUS',

    'CLEAR_COMMENTS',
    'ADD_NEW_ATTACHMENTS',
  ],
  {
    prefix: 'CHAT.',
  },
);
