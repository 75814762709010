import omit from 'lodash/omit';
import { actionTypes } from './actionTypes';
import { invoicesSources } from './sources';

const loadInvoices = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: invoicesSources.root,
      params: {
        ...omit(params, ['date_id', 'all_event__in', 'active_event__in']),
        ...((params.all_event__in || params.active_event__in) && { event__in: params.date_id }),
      },
    },
  },
});

const loadInvoicesMore = () => ({
  type: actionTypes.LOAD_MORE,
});

const loadDetailInvoice = (id: number | string) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    request: {
      url: invoicesSources.detail(id),
    },
  },
});

const clearDetailInvoiceData = () => ({
  type: actionTypes.CLEAR_DETAIL_INVOICE_DATA,
});

const assignToMe = (id: number | string) => ({
  type: actionTypes.ASSIGN_TO_ME,
  payload: {
    request: {
      url: invoicesSources.assignToMe(id),
    },
    id,
  },
});

const assignUser = (id: number | string, data: any) => ({
  type: actionTypes.ASSIGN_USER,
  payload: {
    request: {
      method: 'POST',
      url: invoicesSources.assign(id),
      data,
    },
    id,
  },
});

const setAwait = (id: number | string) => ({
  type: actionTypes.SET_AWAIT,
  payload: {
    request: {
      url: invoicesSources.setAwait(id),
    },
    id,
  },
});

const editUserCustomer = (id: number | string, data: any) => ({
  type: actionTypes.EDIT_USER_CUSTOMER,
  payload: {
    request: {
      method: 'PATCH',
      url: invoicesSources.detail(id),
      data: {
        user_customer: data,
      },
    },
    id,
  },
});

const rejectInvoice = (id: number | string, data: any) => ({
  type: actionTypes.REJECT_INVOICE,
  payload: {
    request: {
      method: 'DELETE',
      url: invoicesSources.detail(id),
      data,
    },
    id,
  },
});

const editInvoiceEvent = (id: number | string, data: any) => ({
  type: actionTypes.EDIT_INVOICE_EVENT,
  payload: {
    request: {
      method: 'PATCH',
      url: invoicesSources.detail(id),
      data,
    },
    id,
  },
});

const createInvoice = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: invoicesSources.create,
      data,
    },
  },
});

const createOrder = (id: number | string) => ({
  type: actionTypes.CREATE_ORDER,
  payload: {
    request: {
      url: invoicesSources.toOrder(id),
    },
    id,
  },
});

export {
  setAwait,
  assignUser,
  assignToMe,
  createOrder,
  loadInvoices,
  rejectInvoice,
  createInvoice,
  editInvoiceEvent,
  loadInvoicesMore,
  editUserCustomer,
  loadDetailInvoice,
  clearDetailInvoiceData,
};
