import config from 'config/config';

const url = config.CRAWLER_API_URL || '';

export const parsersViewersSources = {
  root: `${url}crawler/zriteli/event`,
  detail: (id: number | string) => `${url}crawler/zriteli/event/${id}`,
  link: (id: number | string) => `${url}crawler/zriteli/event/${id}/set-event`,
  unlink: (id: number | string) => `${url}crawler/zriteli/event/${id}/unlink-event`,
  status: (id: number | string) => `${url}crawler/zriteli/event/${id}/set-status`,
};
