import { actionTypes } from './actionTypes';
import { ENUM } from 'shared/constants/PARSERS_STATUS';

export const parsersEventsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_PARSER_STATUS_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              status: action.payload.request.data.status,
            };
          }

          return item;
        }),
      };

    case actionTypes.LINK_PARSER_WITH_EVENT_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.config.id) {
            return {
              ...item,
              status: ENUM.IN_WORK,
              external_id: action.payload.config.eventId.event_id,
            };
          }

          return item;
        }),
      };

    case actionTypes.UNLINK_PARSER_WITH_EVENT_SUCCESS:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.config.id) {
            return {
              ...item,
              status: ENUM.NEW,
              external_id: null,
            };
          }

          return item;
        }),
      };

    default:
      return undefined;
  }
};
