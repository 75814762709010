import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_FAIL',

    'LOAD_DATA',
    'LOAD_DATA_SUCCESS',
    'LOAD_DATA_FAIL',

    'LOAD_MORE',

    'LOAD_DETAIL',
    'LOAD_DETAIL_SUCCESS',
    'LOAD_DETAIL_FAIL',

    'EDIT',
    'EDIT_SUCCESS',
    'EDIT_FAIL',

    'REMOVE',
    'REMOVE_SUCCESS',
    'REMOVE_FAIL',
  ],
  {
    prefix: 'COMPANY_PHONES.',
  },
);
