import config from 'config/config';
import cookies from 'js-cookie';
import nookies from 'nookies';

const setCookie = (key: string, value: string, ctx?: any) => {
  const data = typeof value === 'string' ? value : JSON.stringify(value);
  nookies.set(ctx || null, key, data, {
    domain: config.COOKIE_DOMAIN,
    maxAge: 30 * 24 * 60 * 60,
    path: '/',
  });
};

export const removeServerCookie = (key: string, ctx) => {
  return nookies.destroy(ctx.req, key, {
    domain: config.COOKIE_DOMAIN,
  });
};

const removeCookie = (key: string) => {
  const { hostname } = window.location;

  if (process.env.IS_STAGE) {
    return cookies.remove(key, { path: '/', domain: '.pbilet.org' });
  }

  return cookies.remove(key, { path: '/', domain: `.${hostname}` });
};

const parseCookieObject = (object) => JSON.parse(decodeURI(object).replace(/%2C/g, ','));

const getCookie = (key: string, ctx?: any) => {
  const cookies = ctx ? nookies.get(ctx) : nookies.get();

  return cookies[key];
};

export { getCookie, parseCookieObject, removeCookie, setCookie };
