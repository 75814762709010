import { actionTypes } from './actionTypes';
import { companySources } from '../sources';

const createCompanyPhone = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: companySources.landingPhones,
      data,
    },
  },
});

const loadCompanyPhones = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: companySources.landingPhones,
      params,
    },
  },
});

const loadMoreCompanyPhones = () => ({
  type: actionTypes.LOAD_MORE,
});

const loadCompanyPhone = (id: number | string) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    request: {
      url: companySources.landingPhonesDetail(id),
    },
  },
});

const editCompanyPhone = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: companySources.landingPhonesDetail(id),
      data,
    },
  },
});

const removeCompanyPhone = (id: number | string) => ({
  type: actionTypes.REMOVE,
  payload: {
    request: {
      method: 'DELETE',
      url: companySources.landingPhonesDetail(id),
      id,
    },
  },
});

export {
  loadCompanyPhones,
  loadMoreCompanyPhones,
  createCompanyPhone,
  loadCompanyPhone,
  editCompanyPhone,
  removeCompanyPhone,
};
