import React from 'react';
import Link from '../Link';

interface UserNameProps {
  data: {
    first_name?: string;
    last_name?: string;
    username?: string;
  };
  withoutLink: boolean;
}

const UserName = ({ data, withoutLink = false }: UserNameProps) => {
  const { first_name: firstName, last_name: lastName, username } = data;

  if (!firstName && !lastName) {
    if (!username) return null;
    if (withoutLink) {
      return <>{username}</>;
    }

    return (
      <Link simple href={`mailto:${username}`}>
        {username}
      </Link>
    );
  }

  return <>{`${firstName} ${lastName}`}</>;
};

export default UserName;
