import { actionTypes } from './actionTypes';
import { chartsSources } from './sources';

const loadCharts = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: chartsSources.root,
      params,
    },
  },
});

const loadChartsMore = () => ({
  type: actionTypes.LOAD_MORE,
});

const loadChart = (id: number | string, lng: string) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    request: {
      url: chartsSources.detail(id),
    },
    lng,
    loadLng: lng,
  },
});

const createChart = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: chartsSources.root,
      data,
    },
  },
});

const editChart = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: chartsSources.detail(id),
      data,
    },
  },
});

const deleteChart = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: chartsSources.detail(id),
      id,
    },
  },
});

export { loadCharts, loadChartsMore, loadChart, createChart, editChart, deleteChart };
