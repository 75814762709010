import { actionTypes } from './actionTypes';

const INITIAL_STATE = {
  data: null,
  state: {
    isLoading: false,
  },
};

export function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        data: null,
        state: {
          isLoading: true,
        },
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        state: {
          isLoading: false,
        },
      };
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        data: null,
        state: {
          isLoading: false,
        },
      };
    default:
      return state;
  }
}
