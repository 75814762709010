import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { i18n } from '../../i18n';
import { actionTypes } from './actionTypes';
import { eventsSources } from './sources';

const loadEvents = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: eventsSources.rootv2,
      params,
    },
  },
});

const loadEventsMore = () => ({
  type: actionTypes.LOAD_MORE,
});

const loadMoreEvents = (params = {}) => ({
  type: actionTypes.LOAD_MORE_EVENTS,
  payload: {
    request: {
      url: eventsSources.rootv2,
      params,
    },
  },
});

const loadEvent = (params = { event: null, outdated: false }, lng = i18n?.language || LANGUAGES.RU) => {
  return {
    type: actionTypes.LOAD_DETAIL,
    payload: {
      request: {
        url: eventsSources.root,
        params: { date_id_in: params.event, date_type: 'all' },
      },
      lng,
      loadLng: lng,
    },
  };
};

const createEvent = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: eventsSources.rootv2,
      data,
      params: {
        outdated: true, // TODO: remove this in future
      },
    },
  },
});

const editEvent = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: eventsSources.detail(id),
      data,
      params: {
        outdated: true, // TODO: remove this in future
      },
    },
  },
});

const deleteEvent = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: eventsSources.delete(id),
    },
  },
});

const editEventDates = (data: any) => ({
  type: actionTypes.EDIT_EVENT_DATES,
  payload: {
    request: {
      method: 'POST',
      url: eventsSources.bulkDate,
      data,
      params: {
        outdated: true, // TODO: remove this in future
      },
    },
  },
});

const createEventFromParser = (data: any) => ({
  type: actionTypes.CREATE_FROM_PARSER,
  payload: {
    data,
  },
});

const clearData = () => ({
  type: actionTypes.CLEAR_DATA,
});

const clearDetailData = () => ({
  type: actionTypes.CLEAR_DETAIL_DATA,
});

const loadAnnouncementsCount = (id: number | string, params = {}) => ({
  type: actionTypes.LOAD_ANNOUNCEMENTS_COUNT,
  payload: {
    request: {
      url: eventsSources.countAnnouncements(id),
      params,
    },
  },
});

const loadAnnouncementsMeta = (id: number | string, params = {}) => ({
  type: actionTypes.LOAD_ANNOUNCEMENTS_META,
  payload: {
    request: {
      url: eventsSources.metaAnnouncements(id),
      params,
    },
  },
});

const loadPoolCount = (id: number | string, params = {}) => ({
  type: actionTypes.LOAD_POOL_COUNT,
  payload: {
    request: {
      url: eventsSources.countPool(id),
      params,
    },
  },
});
const loadPoolMeta = (id: number | string, params = {}) => ({
  type: actionTypes.LOAD_POOL_META,
  payload: {
    request: {
      url: eventsSources.metaPool(id),
      params,
    },
  },
});

const getPermission = (id: number | string | null) => ({
  type: actionTypes.GET_PERMISSION,
  payload: {
    request: {
      url: eventsSources.getPermission(id),
    },
  },
});

const mergeEvents = (id: number | string, data: any) => ({
  type: actionTypes.MERGE_EVENTS,
  payload: {
    request: {
      method: 'POST',
      url: eventsSources.mergeEvents(id),
      data,
    },
  },
});

const resetCounters = () => ({
  type: actionTypes.RESET_COUNTS,
});

export {
  loadEvents,
  loadEventsMore,
  loadEvent,
  createEvent,
  editEvent,
  deleteEvent,
  createEventFromParser,
  clearData,
  clearDetailData,
  loadMoreEvents,
  editEventDates,
  loadAnnouncementsCount,
  loadAnnouncementsMeta,
  loadPoolCount,
  loadPoolMeta,
  getPermission,
  mergeEvents,
  resetCounters,
};
