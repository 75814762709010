import { actionTypes } from './actionTypes';
import { parsersCategoriesSources } from './sources';

const loadParsersCategories = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: parsersCategoriesSources.root,
      params,
    },
  },
});

const loadMoreParsersCategories = () => ({
  type: actionTypes.LOAD_MORE,
});

const editParsersCategory = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'POST',
      url: parsersCategoriesSources.edit(id),
      data,
    },
  },
});

const unlinkCategory = (id: number | string) => ({
  type: actionTypes.UNLINK,
  payload: {
    request: {
      method: 'DELETE',
      url: parsersCategoriesSources.unlink(id),
      id,
    },
  },
});

export { loadParsersCategories, loadMoreParsersCategories, editParsersCategory, unlinkCategory };
