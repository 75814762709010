import { actionTypes } from './actionTypes';
import { authSources } from './sources';

const login = ({ username, password }: { username: string; password: string }, nextUrl: string) => ({
  type: actionTypes.LOGIN,
  payload: {
    request: {
      method: 'POST',
      url: authSources.login,
      data: {
        username,
        password,
      },
    },
    nextUrl,
  },
});

const registration = ({ username, password }: { username: string; password: string }) => ({
  type: actionTypes.REGISTRATION,
  payload: {
    request: {
      method: 'POST',
      url: authSources.registration,
      data: {
        username,
        password,
      },
    },
  },
});

const logout = () => ({
  type: actionTypes.LOGOUT,
  payload: {
    request: {
      url: authSources.logout,
    },
  },
});

const changePassword = (data: any) => ({
  type: actionTypes.CHANGE_PASSWORD,
  payload: {
    request: {
      method: 'POST',
      url: authSources.changePassword,
      data,
    },
  },
});

const resetPassword = (data: any) => ({
  type: actionTypes.RESET_PASSWORD,
  payload: {
    request: {
      method: 'POST',
      url: authSources.resetPassword,
      data,
    },
  },
});

const renewPassword = (data: any) => ({
  type: actionTypes.RENEW_PASSWORD,
  payload: {
    request: {
      method: 'POST',
      url: authSources.renew,
      data,
    },
  },
});

const loadPermissions = () => ({
  type: actionTypes.LOAD_PERMISSIONS,
  payload: {
    request: {
      url: authSources.userPermissions,
      params: { limit: 99999 },
    },
  },
});

const loadCompanyPermissions = () => ({
  type: actionTypes.LOAD_COMPANY_PERMISSIONS,
  payload: {
    request: {
      url: authSources.companyPermissions,
      params: { limit: 99999 },
    },
  },
});

const loadAdminPermissions = () => ({
  type: actionTypes.LOAD_ADMIN_PERMISSIONS,
  payload: {
    request: {
      url: authSources.adminPermissions,
      params: { limit: 99999 },
    },
  },
});

export {
  login,
  logout,
  registration,
  changePassword,
  resetPassword,
  renewPassword,
  loadPermissions,
  loadAdminPermissions,
  loadCompanyPermissions,
};
