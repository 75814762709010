import { actionTypes } from './actionTypes';
import { hallLayoutsSources } from 'entities/hall-layouts';

const loadHallLayouts = (params = {}) => ({
  type: actionTypes.LOAD,
  payload: {
    request: {
      url: hallLayoutsSources.root,
      params,
    },
  },
});

const createHallLayout = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: hallLayoutsSources.root,
      data,
    },
  },
});

const deleteHallLayout = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: hallLayoutsSources.detail(id),
    },
  },
});

const editHallLayout = (id: number | string, data: any) => ({
  type: actionTypes.UPDATE,
  payload: {
    request: {
      method: 'PATCH',
      url: hallLayoutsSources.detail(id),
      data,
    },
  },
});

export { loadHallLayouts, createHallLayout, deleteHallLayout, editHallLayout };
