import styled from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';
import { darkScrollbar } from '../../app/styles/scrollbar';

export default {
  Container: styled.div`
    position: relative;

    @media (max-width: ${BREAKPOINTS.XL}) {
      position: fixed;
      z-index: 20;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      pointer-events: none;
      left: ${(props) => (props.theme.sidebarIsOpen ? '0' : '-100%')};
      background-color: ${(props) => (props.theme.sidebarIsOpen ? 'rgba(0, 0, 0, 0.4)' : null)};
      pointer-events: ${(props) => (props.theme.sidebarIsOpen ? 'all' : 'none')};
    }
  `,

  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.theme.sidebarIsOpen ? '224px' : '64px')};
    height: 100%;
    background-color: #000;
    overflow: hidden;
    pointer-events: all;

    @media (max-width: ${BREAKPOINTS.XL}) {
      width: 288px;
      min-width: 288px;
      max-width: 288px;
    }
  `,

  Top: styled.div`
    display: flex;
    align-items: center;
    min-height: 64px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    box-sizing: border-box;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      display: block;
      background-color: #000;
      height: 100%;
      width: 12px;
      right: 0;
      z-index: 1;
      pointer-events: none;
    }
  `,

  Logo: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding-left: ${(props) => (props.theme.sidebarIsOpen ? '49px' : '18px')};

    & > img {
      height: auto;
      width: 125px;
    }

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,

  Nav: styled.nav`
    padding: 16px 0px 0;
    overflow-x: hidden;
    overflow-y: auto;

    @media (min-width: ${BREAKPOINTS.XL}) {
      ${darkScrollbar};
    }
  `,

  Menu: styled.div`
    margin-bottom: 16px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      margin-bottom: 32px;
    }
  `,

  MenuTitle: styled.div`
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.64);
    opacity: ${(props) => (!props.theme.sidebarIsOpen ? '0' : '1')};
    padding: 0 22px 8px;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: ${BREAKPOINTS.XL}) {
      opacity: 1;
      font-size: 12px;
      padding: 4px 16px;
      padding-bottom: 18px;
    }
  `,

  Close: styled.div`
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: ${(props) => (props.theme.sidebarIsOpen ? 'block' : 'none')};
      pointer-events: all;
    }
  `,

  UserMobileInfo: styled.a`
    display: none;
    flex-grow: 1;
    height: 100%;
    align-items: center;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: flex;
    }
  `,

  UserInfo: styled.div`
    flex-direction: column;
    color: #fff;
    margin-left: 16px;

    & > div {
      margin-bottom: 8px;
    }
  `,

  MobileNav: styled.div`
    flex-direction: column;
    margin: 2px 16px 0 auto;
  `,
};
