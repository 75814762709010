import { widgetSources } from './sources';
import { actionTypes } from './actionTypes';
import { i18n } from '../../i18n';

const loadWidgets = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: widgetSources.root,
      params,
    },
  },
});

const loadMoreWidgets = () => ({
  type: actionTypes.LOAD_MORE,
});

const createWidget = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    request: {
      method: 'POST',
      url: widgetSources.root,
      data,
    },
  },
});

const loadWidget = (id: string | number, params, lng: string = i18n.language) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    request: {
      url: widgetSources.detail(id),
      params,
    },
    lng,
    loadLng: lng,
  },
});

const editWidget = (data: any, lng: string = i18n.language) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'PATCH',
      url: widgetSources.detail(data.widget_id || data.id),
      data,
    },
    lng,
    loadLng: lng,
  },
});

const deleteWidget = (id: number | string) => ({
  type: actionTypes.DELETE,
  payload: {
    request: {
      method: 'DELETE',
      url: widgetSources.detail(id),
    },
  },
});

export { loadWidgets, loadMoreWidgets, createWidget, loadWidget, deleteWidget, editWidget };
