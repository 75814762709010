import { parseCookies } from 'nookies';
import COOKIES from 'shared/constants/COOKIES';

export const getToken = (ctx?) => {
  const cookies = ctx ? parseCookies(ctx) : parseCookies();

  return cookies[COOKIES.TOKEN];
};

export const isAuthenticated = (ctx) => !!getToken(ctx);
