import omit from 'lodash/omit';
import { actionTypes } from './actionTypes';
import { preordersSources } from './sources';

const loadPreorders = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: preordersSources.root,
      params: {
        ...omit(params, ['date_id', 'event__in']),
        ...(params.event__in && params.date_id && { date_id_in: params.date_id }),
      },
    },
  },
});

const loadPreordersMore = () => ({
  type: actionTypes.LOAD_MORE,
});

const changePreorderExtra = (id: number | string, data: any) => ({
  type: actionTypes.CHANGE_PREORDER_EXTRA,
  payload: {
    request: {
      method: 'POST',
      url: preordersSources.extra(id),
      data,
    },
  },
});

const finishPreorder = (id: number | string) => ({
  type: actionTypes.FINISH_PREORDER,
  payload: {
    request: {
      url: preordersSources.finish(id),
      id,
    },
  },
});

const loadPreorder = (id: number | string) => ({
  type: actionTypes.LOAD_DETAIL,
  payload: {
    client: 'ticketsApi',
    request: {
      url: preordersSources.detail(id),
    },
  },
});

export { loadPreorders, loadPreordersMore, changePreorderExtra, finishPreorder, loadPreorder };
