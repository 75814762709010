import { combineReducers } from 'redux';
import { accountReducer } from 'entities/account';
import { authReducer } from 'entities/auth';
import { companyReducer } from 'entities/company';
import { poolDetailReducer } from 'entities/pool/detail';
import { poolSchemeReducer } from 'entities/pool/scheme';
import { userAuditReducer } from 'entities/user-audit';
import { eventsReducer } from 'entities/events';
import { eventsDetailReducer } from 'entities/events/detail';
import { NAME as CompanyActionsName } from 'entities/company';
import { userAnnouncementDetail } from 'entities/user-announcement';
import { ordersDetailReducer } from 'entities/orders/detail';
import { parsersEventsReducer } from 'entities/parsers/events';
import { parsersViewersReducer } from 'entities/parsers/viewers';
import { parsersVenuesReducer } from 'entities/parsers/venues';
import { parsersCategoriesReducer } from 'entities/parsers/categoires';
import { salesReducer } from 'entities/orders/sales';
import { purchasesReducer } from 'entities/orders/purchases';
import { searchOrdersReducer } from 'entities/search';
import { preordersReducer } from 'entities/preorders';
import { companyRolesReducer } from 'entities/role';
import { companyMembersReducer } from 'entities/company/members';
import { companyProvidersReducer } from 'entities/company/providers';
import { companyPhonesReducer } from 'entities/company/phones';
import { companyPaymentsReducer } from 'entities/company/payments';
import { companyEmailsConfigReducer } from 'entities/company/emails-config';
import { companyLegalsInfoReducer } from 'entities/company/legals-info';
import { companyAtolConfigReducer } from 'entities/company/atol-config';
import { invoicesReducer } from 'entities/invoices';
import { invoicesDetailReducer } from 'entities/invoices/detail';
import { usersReducer } from 'entities/members/users';
import { companiesReducer } from 'entities/members/companies';
import widgetv2 from 'features/widgets';

import { createReferenceTabReducer } from 'entities/shared';
import { createContactsReducer } from 'entities/shared';
import { createTableReducer } from 'entities/shared';
import { createNormalzedTableReducer } from 'entities/shared';
import { createSearchReducer } from 'entities/shared';
import { layoutReducer } from 'entities/layout';
import { callContextReducer } from 'entities/call-context';
import { orderContextReducer } from 'entities/order/order-context-reducer';
import { hallLayoutsReducer } from 'entities/hall-layouts';
import { widgetPreviewReducer } from 'entities/widget-preview/reducer';
import { modalReducer } from 'entities/modal';
import { selectedAnnouncementsReducer } from 'entities/selected-announcements';
import { chartsReducer } from 'entities/charts';
import { NAME as AccountActionsName } from 'entities/account/actionTypes';
import { chatReducer } from 'entities/chat';

import { bookingReducer } from 'entities/booking';
import { deliveryReducer } from 'entities/delivery';
import { paymentReducer } from 'entities/payment';
import { draftsReducer } from 'entities/drafts';
import { widgetReducer } from 'entities/widget';
import { tablesReducer } from 'entities/tables';
import { permissionsReducer } from 'entities/permissions';

export default combineReducers({
  widgetv2,
  account: accountReducer,
  auth: authReducer,
  booking: bookingReducer,
  callContext: callContextReducer,
  chat: chatReducer,
  company: companyReducer,
  companyContacts: createContactsReducer(CompanyActionsName),
  companyMembers: createTableReducer('COMPANY_MEMBERS', companyMembersReducer),
  companyPayments: createTableReducer('COMPANY_PAYMENT_METHODS', companyPaymentsReducer),
  companyProviders: createTableReducer('COMPANY_PROVIDERS', companyProvidersReducer),
  companyRoles: createTableReducer('COMPANY_ROLES', companyRolesReducer),
  companyCreate: createTableReducer('COMPANY_CREATE'),
  companyEmailsConfig: createTableReducer('COMPANY_EMAILS', companyEmailsConfigReducer),
  companyLegalsInfo: createTableReducer('COMPANY_LEGALS', companyLegalsInfoReducer),
  companyAtolConfig: createTableReducer('COMPANY_ATOL', companyAtolConfigReducer),
  companyPhones: createTableReducer('COMPANY_PHONES', companyPhonesReducer),
  currency: createReferenceTabReducer('CURRENCY'),
  delivery: deliveryReducer,
  drafts: draftsReducer,
  event: eventsDetailReducer,
  events: createTableReducer('EVENTS', eventsReducer),
  charts: createTableReducer('CHARTS', chartsReducer),
  hallLayouts: hallLayoutsReducer,
  halls: createReferenceTabReducer('HALLS'),
  layout: layoutReducer,
  membersCompanies: createTableReducer('MEMBERS_COMPANIES', companiesReducer),
  membersUsers: createTableReducer('MEMBERS_USERS', usersReducer),
  modal: modalReducer,
  orderContext: orderContextReducer,
  orderDetail: ordersDetailReducer,
  parsersEvents: createTableReducer('PARSERS_EVENTS', parsersEventsReducer),
  parsersViewers: createTableReducer('PARSERS_VIEWERS', parsersViewersReducer),
  parsersVenues: createTableReducer('PARSERS_VENUES', parsersVenuesReducer),
  parsersCategories: createTableReducer('PARSERS_CATEGORIES', parsersCategoriesReducer),
  payment: paymentReducer,
  permissions: permissionsReducer,
  poolDetail: createNormalzedTableReducer('POOL_DETAIL', poolDetailReducer),
  poolScheme: poolSchemeReducer,
  preorders: createTableReducer('PREORDERS', preordersReducer),
  invoices: createTableReducer('INVOICES', invoicesReducer),
  invoicesDetail: invoicesDetailReducer,
  profileConstacts: createContactsReducer(AccountActionsName),
  purchases: createTableReducer('PURCHASES', purchasesReducer),
  sales: createNormalzedTableReducer('SALES', salesReducer),
  searchAnnouncementEvents: createSearchReducer('ANNOUNCEMENT_EVENTS'),
  searchEvents: createSearchReducer('EVENTS'),
  searchOrders: createSearchReducer('ORDERS', searchOrdersReducer),
  searchPoolEvents: createSearchReducer('POOL_EVENTS'),
  selectedAnnouncements: selectedAnnouncementsReducer,
  tables: tablesReducer,
  userAnnouncementDetail: createTableReducer('ANNOUNCEMENT_DETAIL', userAnnouncementDetail),
  userAnnouncements: createTableReducer('ANNOUNCEMENT'),
  userAudit: userAuditReducer,
  widget: createNormalzedTableReducer('WIDGET', widgetReducer, { idAttribute: 'event_id' }),
  widgetPreview: widgetPreviewReducer,
  widgetsTemplates: createTableReducer('WIDGET_PATTERNS'),
});
