import styled, { css } from 'styled-components';
import BREAKPOINTS from 'shared/constants/BREAKPOINTS';

interface NavItemProps {
  isUserNav?: boolean;
}

interface BreadcrumbsProps {
  mobile?: boolean;
}

export default {
  Container: styled.div`
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e1e1e1;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    position: relative;
    z-index: 15;
    flex-shrink: 0;

    @media (min-width: ${BREAKPOINTS.XL}) {
      height: 64px;

      &::before {
        content: '';
        position: absolute;
        left: -8px;
        top: 0;
        width: 8px;
        height: 100%;
        border-top-left-radius: 8px;
        border-bottom: 1px solid #e1e1e1;
        background: #fff;
      }
    }
  `,

  Nav: styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-right: 15px;
    /* order: 5; */

    @media (max-width: ${BREAKPOINTS.XL}) {
      padding-right: 16px;
    }
  `,

  NavItem: styled.div<NavItemProps>`
    &:not(:last-child) {
      margin-right: 16px;
    }

    @media (max-width: ${BREAKPOINTS.XL}) {
      &:not(:last-child) {
        margin-right: 0;
      }

      ${(props) =>
        props.isUserNav &&
        css`
          display: none;
        `}
    }
  `,

  Logo: styled.div`
    display: none;
    align-items: center;
    order: 4;

    & img {
      width: 135px;
      height: 28px;
    }

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: flex;
      order: 3;
    }
  `,

  Hamburger: styled.div`
    margin-right: 32px;
    display: none;
    align-items: center;
    justify-content: center;
    order: 1;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: flex;
    }
  `,

  OrderContext: styled.button`
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 200px;
    border: 0;
    border-radius: 8px 0px 0px 8px;
    margin: 0;
    padding: 12px 0;
    outline: none;
    text-align: left;
    background: #fff6c2;
    font-size: 18px;
    cursor: pointer;

    @media (max-width: ${BREAKPOINTS.MD}) {
      position: fixed;
      z-index: 2;

      left: 0;
      bottom: 0;
      width: 100%;
      height: 56px;
      border-radius: 0;
      padding: 0 0 0 24px;
      font-size: 12px;
    }
  `,

  OrderContextText: styled.div`
    @media (max-width: ${BREAKPOINTS.MD}) {
      display: flex;
      flex-direction: column;

      & > div {
        margin: 0;
      }
    }
  `,

  CaretIcon: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    opacity: 0.4;
    transform: rotate(180deg);

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  MobileCaretIcon: styled.div`
    display: none;
    margin-left: auto;
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: flex;
    }
  `,

  Br: styled.br`
    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  OrderDesc: styled.div`
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: none;
    }
  `,

  OrderDescMobile: styled.div`
    font-size: 12px;
    color: #5c5946;
    display: inline-block;
    margin-left: 8px;
    display: none;

    @media (max-width: ${BREAKPOINTS.MD}) {
      display: inline-block;
    }
  `,

  Button: styled.button``,

  Title: styled.div`
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    line-height: 31px;
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    right: 230px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      right: 140px;
      font-size: 16px;
    }
  `,

  Search: styled.div`
    width: 100%;
    margin-right: 16px;
    margin-left: auto;

    @media (max-width: ${BREAKPOINTS.XL}) {
      margin-right: 0;
    }
  `,

  Breadcrumbs: styled.div<BreadcrumbsProps>`
    display: flex;
    align-items: center;
    font-size: 16px;
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    right: 230px;

    a {
      color: #000;
      opacity: 0.64;

      &:hover {
        opacity: 1;
        color: #000;
      }
    }

    ${({ mobile }) =>
      mobile &&
      css`
        box-sizing: border-box;
        position: relative;
        top: 0;
        left: 0;
        right: auto;
        padding: 4px 0;
        background-color: #fff;
        border-bottom: 1px solid #e1e1e1;
        height: 40px;
        transform: none;
        flex-shrink: 0;
        white-space: initial;
        z-index: 5;
      `}

    @media (max-width: ${BREAKPOINTS.MD}) {
      right: auto;
    }
  `,

  BreadcrumbsIcon: styled.div`
    margin: 0 12px;

    @media (max-width: ${BREAKPOINTS.XL}) {
      display: none;
    }
  `,

  User: styled.div`
    width: 40px;
    height: 40px;
    font-weight: bold;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    background: #999;
    border-radius: 20px;
  `,
};
