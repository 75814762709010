import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD_DATA',
    'LOAD_DATA_SUCCESS',
    'LOAD_DATA_FAIL',

    'LOAD_MORE',

    'LOAD_MORE_EVENTS',
    'LOAD_MORE_EVENTS_SUCCESS',
    'LOAD_MORE_EVENTS_FAIL',

    'LOAD_DETAIL',
    'LOAD_DETAIL_SUCCESS',
    'LOAD_DETAIL_FAIL',

    'CREATE',
    'CREATE_SUCCESS',
    'CREATE_FAIL',

    'CREATE_FROM_PARSER',

    'EDIT',
    'EDIT_SUCCESS',
    'EDIT_FAIL',

    'DELETE',
    'DELETE_SUCCESS',
    'DELETE_FAIL',

    'CLEAR_DATA',
    'CLEAR_DETAIL_DATA',

    'EDIT_EVENT_DATES',
    'EDIT_EVENT_DATES_SUCCESS',
    'EDIT_EVENT_DATES_FAIL',

    'LOAD_ANNOUNCEMENTS_COUNT',
    'LOAD_ANNOUNCEMENTS_COUNT_SUCCESS',
    'LOAD_ANNOUNCEMENTS_COUNT_FAIL',

    'LOAD_ANNOUNCEMENTS_META',
    'LOAD_ANNOUNCEMENTS_META_SUCCESS',
    'LOAD_ANNOUNCEMENTS_META_FAIL',

    'LOAD_POOL_COUNT',
    'LOAD_POOL_COUNT_SUCCESS',
    'LOAD_POOL_COUNT_FAIL',

    'LOAD_POOL_META',
    'LOAD_POOL_META_SUCCESS',
    'LOAD_POOL_META_FAIL',

    'GET_PERMISSION',
    'GET_PERMISSION_SUCCESS',
    'GET_PERMISSION_FAIL',

    'MERGE_EVENTS',
    'MERGE_EVENTS_SUCCESS',
    'MERGE_EVENTS_FAIL',

    'RESET_COUNTS',
  ],
  {
    prefix: 'EVENTS.',
  },
);
