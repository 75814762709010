import { actionTypes } from './actionTypes';

const addSessionToken = (token: string) => ({
  type: actionTypes.ADD_TOKEN,
  payload: {
    token,
  },
});

const removeSessionToken = () => ({
  type: actionTypes.REMOVE_TOKEN,
});

export { addSessionToken, removeSessionToken };
