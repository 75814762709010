import { actionTypes } from 'entities/widget-preview';

const INITIAL_STATE = {
  data: null,
  error: null,
  state: {
    isLoading: false,
    isFail: false,
  },
  createState: {
    isLoading: false,
  },
};

export const widgetPreviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        ...state,
        data: null,
        error: null,
        state: {
          isLoading: true,
          isFail: false,
        },
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    case actionTypes.LOAD_FAIL:
      return {
        ...state,
        data: null,
        error: action.error.response.data.error.join(' '),
        state: {
          isLoading: false,
          isFail: true,
        },
      };
    case actionTypes.CREATE:
      return {
        ...state,
        createState: {
          isLoading: true,
        },
      };
    case actionTypes.CREATE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        createState: {
          isLoading: false,
        },
      };
    case actionTypes.CREATE_FAIL:
      return {
        ...state,
        createState: {
          isLoading: false,
        },
      };
    default:
      return state;
  }
};
