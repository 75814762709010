import { actionTypes } from './actionTypes';
import { parsersVenuesSources } from './sources';

const loadParsersVenues = (params = {}) => ({
  type: actionTypes.LOAD_DATA,
  payload: {
    request: {
      url: parsersVenuesSources.root,
      params,
    },
  },
});

const loadMoreParsersVenues = () => ({
  type: actionTypes.LOAD_MORE,
});

const editParsersVenue = (id: number | string, data: any) => ({
  type: actionTypes.EDIT,
  payload: {
    request: {
      method: 'POST',
      url: parsersVenuesSources.edit(id),
      data,
    },
  },
});

const unlinkVenue = (id: number | string) => ({
  type: actionTypes.UNLINK,
  payload: {
    request: {
      method: 'DELETE',
      url: parsersVenuesSources.unlink(id),
      id,
    },
  },
});

export { loadParsersVenues, loadMoreParsersVenues, editParsersVenue, unlinkVenue };
