import map from 'lodash/map';
import { actionTypes } from './actionTypes';

export const usersReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_VIEWERS_ACCESS_SUCCESS: {
      const { id } = action.meta.previousAction.payload.request;

      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              zriteli_access: !item.zriteli_access,
            };
          }

          return item;
        }),
      };
    }

    case actionTypes.APPROVE_SUCCESS: {
      const { id } = action.meta.previousAction.payload;

      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              verified: true,
            };
          }

          return item;
        }),
      };
    }

    case actionTypes.SET_MEMBER_IS_ACTIVE_SUCCESS: {
      const {
        data: { is_active: isActive },
        id,
      } = action.meta.previousAction.payload.request;

      return {
        ...state,
        data: map(state.data, (item) => {
          if (item.id === id) {
            return { ...item, is_active: isActive };
          }

          return item;
        }),
      };
    }

    case actionTypes.LOAD_DETAIL_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: map(state.data, (item) => {
          if (item.id === data.id) {
            return data;
          }

          return item;
        }),
      };
    }

    default:
      return undefined;
  }
};
