import { actionTypes } from './actionTypes';

export const eventsReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_DATA: {
      return {
        ...state,
        data: [],
      };
    }

    default:
      return undefined;
  }
};
