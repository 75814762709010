import { actionTypes } from './actionTypes';

const createContext = (data: any) => ({
  type: actionTypes.CREATE,
  payload: {
    data,
  },
});

const removeContext = () => ({
  type: actionTypes.REMOVE,
});

const contextShouldOpen = () => ({
  type: actionTypes.SHOULD_OPEN,
});

const contextShouldClose = () => ({
  type: actionTypes.SHOULD_CLOSE,
});

export { createContext, removeContext, contextShouldOpen, contextShouldClose };
