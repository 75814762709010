import { actionTypes } from 'entities/call-context';
import { setCookie, removeCookie } from 'shared/lib/cookie';
import COOKIES from 'shared/constants/COOKIES';

const callContextMiddleware = () => (next) => (action) => {
  switch (action.type) {
    case actionTypes.CREATE: {
      const { name, phone } = action.payload.data;

      setCookie(COOKIES.CALL_CONTEXT, JSON.stringify({ name, phone }));
      break;
    }
    case actionTypes.REMOVE: {
      removeCookie(COOKIES.CALL_CONTEXT);
      break;
    }
    default:
      break;
  }

  next(action);
};

export default callContextMiddleware;
