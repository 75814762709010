import { actionTypes } from './actionTypes';

const INITIAL_STATE = {
  data: null,
  state: {
    isLoading: false,
    isFail: false,
  },
};

export function draftsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD_DRAFTS:
      return {
        ...state,
        state: {
          isLoading: true,
          isFail: false,
        },
      };
    case actionTypes.LOAD_DRAFTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data.results,
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    case actionTypes.LOAD_DRAFTS_FAIL:
      return {
        ...state,
        state: {
          isLoading: false,
          isFail: true,
        },
      };

    case actionTypes.DELETE_DRAFT_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.config.id),
      };
    }

    case actionTypes.EDIT_DRAFT_SUCCESS: {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.config.id) {
            return {
              ...item,
              ...action.payload.data,
            };
          }

          return item;
        }),
        updateState: {
          isLoading: false,
        },
      };
    }

    default:
      return state;
  }
}
