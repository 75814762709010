import { connect } from 'react-redux';
import { canView } from 'shared/helpers/permissions';

interface CanProps {
  children: any;
  fallback?: () => any;
  permission: string | string[];
  permissions?: {
    [key: string]: string;
  };
}

const Can = ({ permission, permissions, fallback, children }: CanProps) =>
  canView(permissions, permission) ? children : fallback();

Can.defaultProps = {
  fallback: () => null,
};

const mapStateToProps = (state) => ({
  permissions: state.permissions,
});
export default connect(mapStateToProps)(Can);
