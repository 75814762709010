import { actionTypes } from './actionTypes';

const toggleBarState = (data: any) => ({
  type: actionTypes.TOGGLE_BAR_STATE,
  payload: {
    data,
  },
});

const setBarState = (data: any) => ({
  type: actionTypes.SET_BAR_STATE,
  payload: {
    data,
  },
});

const selectRow = (data: any) => ({
  type: actionTypes.SELECT_ROW,
  payload: {
    data,
  },
});

const selectAllRows = (data: any) => ({
  type: actionTypes.SELECT_ALL_ROWS,
  payload: {
    data,
  },
});

const unSelectAllRows = (data: any) => ({
  type: actionTypes.UNSELECT_ALL_ROWS,
  payload: {
    data,
  },
});

const setRowsErrors = (data: any) => ({
  type: actionTypes.SET_ROWS_ERRORS,
  payload: {
    data,
  },
});

const selectManyRows = (data: any) => ({
  type: actionTypes.SELECT_MANY_ROWS,
  payload: {
    data,
  },
});

const updateQueries = (data: any) => ({
  type: actionTypes.UPDATE_QUERIES,
  payload: {
    data,
  },
});

const setQueries = (data: any) => ({
  type: actionTypes.SET_QUERIES,
  payload: {
    data,
  },
});

const expandAllRows = (data: any) => ({
  type: actionTypes.EXPAND_ALL_ROWS,
  payload: {
    data,
  },
});

const setExpandedAllRows = (data: any) => ({
  type: actionTypes.SET_EXPANDED_ALL_ROWS,
  payload: {
    data,
  },
});

const toggleShowOnlyErrors = (data: any) => ({
  type: actionTypes.TOGGLE_SHOW_ONLY_ERRORS,
  payload: {
    data,
  },
});

const setDisabled = (data: any) => ({
  type: actionTypes.SET_DISABLED,
  payload: {
    data,
  },
});

export {
  toggleBarState,
  setBarState,
  selectRow,
  selectAllRows,
  unSelectAllRows,
  setRowsErrors,
  selectManyRows,
  updateQueries,
  setQueries,
  expandAllRows,
  setExpandedAllRows,
  toggleShowOnlyErrors,
  setDisabled,
};
