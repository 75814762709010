import { actionTypes } from './actionTypes';

const INITIAL_STATE = {
  data: [],
  meta: {},
  state: {
    isLoading: true,
    isFail: false,
  },
};

export function userAuditReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        data: [],
        meta: {},
        state: {
          isLoading: true,
          isFail: false,
        },
      };
    case actionTypes.LOAD_SUCCESS: {
      const { results: data, ...meta } = action.payload.data.data;

      return {
        data,
        meta,
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    }
    case actionTypes.LOAD_FAIL:
      return {
        data: [],
        meta: {},
        state: {
          isLoading: false,
          isFail: true,
        },
      };
    case actionTypes.LOAD_MORE_SUCCESS: {
      const { results: data, ...meta } = action.payload.data.data;

      return {
        ...state,
        data: [...state.data, ...data],
        meta,
      };
    }
    default:
      return state;
  }
}
