import map from 'lodash/map';
import React, { Fragment } from 'react';
import Styled from './styles';

interface Props {
  suffix?: string;
  withSuffix?: boolean;
  children?: string | number;
  icon?: string;
  white?: boolean;
  currency?: string;
}

const SplittedNumber: React.FC<Props> = ({ children, withSuffix, suffix, icon, white, currency }) => {
  if (children === undefined || children === null) return null;

  const displayIcon = icon || currency || false;
  const currencyIcon = !!currency;

  const numbers = children.toString();
  const numbersStart = numbers.slice(0, numbers.length % 3);
  const numbersRest: any = numbers.slice(numbers.length % 3);

  const formattedNumbers = map([...numbersRest], (number, index) => {
    const numberPosition = index + 1;

    if (numberPosition % 3 === 0 && numberPosition !== numbersRest.length) {
      return <Styled.Number key={`${index}_${number}`}>{number}</Styled.Number>;
    }

    return <Fragment key={`${index}_${number}`}>{number}</Fragment>;
  });

  if (numbersStart) {
    formattedNumbers.unshift(<Styled.Number key="start">{numbersStart}</Styled.Number>);
  }

  if (numbers.length <= 3) {
    if (withSuffix) {
      return (
        <>
          {numbers}{' '}
          {displayIcon ? <Styled.Icon white={white} src={displayIcon} currencyIcon={currencyIcon} /> : suffix}
        </>
      );
    }
  }

  if (withSuffix) {
    return (
      <>
        {formattedNumbers}{' '}
        {displayIcon ? <Styled.Icon white={white} src={displayIcon} currencyIcon={currencyIcon} /> : suffix}
      </>
    );
  }

  return <>{formattedNumbers}</>;
};

SplittedNumber.defaultProps = {
  suffix: '₽',
};

export default SplittedNumber;
