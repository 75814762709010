import get from 'lodash/get';
import { createSelector } from 'reselect';
import { HYDRATE } from 'next-redux-wrapper';
import { actionTypes } from './actionTypes';
import { actionTypes as companyActionTypes } from 'entities/company';
import { IUser } from './types';

function prepareUserData(data) {
  return {
    ...data,
    isOwner: get(data.company, 'owner_id') === data.id && get(data.company, 'verified'),
  };
}

const INITIAL_STATE = {
  data: {},
  state: {
    isLoading: true,
    isFail: false,
  },
};

export function accountReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.LOAD:
      return {
        data: {},
        state: {
          isLoading: true,
          isFail: false,
        },
      };
    case actionTypes.LOAD_SUCCESS:
      return {
        data: prepareUserData(action.payload.data.data),
        state: {
          isLoading: false,
          isFail: false,
        },
      };
    case actionTypes.EDIT_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
      };
    case actionTypes.SET_CURRENT_USER:
      return {
        ...state,
        data: prepareUserData(action.payload.data),
      };
    case companyActionTypes.CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          company: {
            verified: false,
          },
        },
      };

    case HYDRATE:
      return { ...state, ...action.payload.account };

    default:
      return state;
  }
}

export const selectAccount = createSelector(
  (state) => {
    return state.account.data;
  },
  (data: IUser) => data,
);
