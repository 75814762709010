import mirrorCreator from 'mirror-creator';

export const actionTypes = mirrorCreator(
  [
    'LOAD_ORDERS',
    'LOAD_ORDERS_SUCCESS',
    'LOAD_ORDERS_FAIL',

    'LOAD_EVENTS',
    'LOAD_EVENTS_SUCCESS',
    'LOAD_EVENTS_FAIL',

    'LOAD_ANNOUNCEMENT_EVENTS',
    'LOAD_ANNOUNCEMENT_EVENTS_SUCCESS',
    'LOAD_ANNOUNCEMENT_EVENTS_FAIL',

    'LOAD_POOL_EVENTS',
    'LOAD_POOL_EVENTS_SUCCESS',
    'LOAD_POOL_EVENTS_FAIL',
  ],
  {
    prefix: 'SEARCH.',
  },
);
