import { actionTypes } from './actionTypes';

const openModal = (modalType: string, data?: any, state?: any) => ({
  type: actionTypes.OPEN,
  payload: {
    modalType,
    data,
    state,
  },
});

const closeModal = () => ({
  type: actionTypes.CLOSE,
});

const openRouterModal = ({
  modalType,
  data,
  modalState,
  query,
}: {
  modalType: string;
  data: any;
  modalState?: string;
  query?: any;
}) => ({
  type: actionTypes.OPEN_ROUTER_MODAL,
  payload: {
    modalType,
    data,
    modalState,
    query,
  },
});

const closeRouterModal = () => ({
  type: actionTypes.CLOSE_ROUTER_MODAL,
});

const addPreviousModal = (modalType: string, data: any) => ({
  type: actionTypes.ADD_PREVIOUS_MODAL,
  payload: {
    modalType,
    data,
  },
});

const removePreviousModal = () => ({
  type: actionTypes.REMOVE_PREVIOUS_MODAL,
});

const changeModalState = (state: any) => ({
  type: actionTypes.CHANGE_STATE,
  payload: {
    state,
  },
});

const editModalData = (data: any) => ({
  type: actionTypes.EDIT_DATA,
  payload: {
    data,
  },
});

export {
  openModal,
  closeModal,
  openRouterModal,
  closeRouterModal,
  addPreviousModal,
  removePreviousModal,
  changeModalState,
  editModalData,
};
