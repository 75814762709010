import { actionTypes, loadOrder } from 'entities/order';

const orderMiddleware = (store) => (next) => (action) => {
  next(action);

  switch (action.type) {
    case actionTypes.FINISH_ORDER_SUCCESS:
    case actionTypes.ASSIGN_USER_SUCCESS:
    case actionTypes.CANCEL_ORDER_SUCCESS:
    case actionTypes.DELETE_EXTERNAL_ID_SUCCESS:
    case actionTypes.DELETE_TICKETS_SUCCESS:
    case actionTypes.SET_DISCOUNT_SUCCESS:
    case actionTypes.ADD_TICKETS_FROM_POOL_SUCCESS:
    case actionTypes.REFUND_SUCCESS:
    case actionTypes.DELETE_DISCOUNT_SUCCESS:
    case actionTypes.EDIT_USER_CUSTOMER_SUCCESS:
    case actionTypes.SET_TICKET_SEARCH_SUCCESS: {
      const { id } = action.meta.previousAction.payload;

      store.dispatch(loadOrder(id));
      break;
    }
    default:
  }
};

export default orderMiddleware;
